import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService, UserAuthenticationService} from '@galec/services';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '@galec/environments/environment';
import {KeycloakService} from 'keycloak-angular';
import {Location} from '@angular/common';
import {LangService} from "@galec/services/global/lang.service";

@Component({
    selector: 'galec-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    redirect = true;
    counter = 5;

    constructor(
        private userAuthenticationService: UserAuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        private langService: LangService
    ) {
    }

    ngOnInit(): void {
        console.log('Login composant');
        this.userAuthenticationService.isLoggedIn().then(logged => {
            if (logged && this.userAuthenticationService.isRedirected) {
                const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
                // this.router.navigate([returnUrl ?? '/app/home']);
                //this.router.navigate(['/app/home']);
                this.langService.navigate('/app/home');
            } else {
                // Waiting for redirection page.
                this.redirectCallback();
            }
        });
    }

    /**
     * Waiting for redirection page.
     */
    redirectCallback() {
        const interval = setInterval(() => {
            this.counter--;
            if (this.counter === 0) {
                this.userAuthenticationService.isRedirected = true;
                clearInterval(interval);
                window.location.reload();
            }
        }, 1000);
    }

}
