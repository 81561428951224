<mat-spinner *ngIf="loading"></mat-spinner>
<!-- mobile nav -->
<header class="website__header d-lg-none">
    <!-- add class c-nav--active below to open nav menu -->
    <nav class="c-nav">
        <div class="container">
            <div class="c-nav__header">
                <button (click)="burgerMenuOpen = !burgerMenuOpen"
                        class="c-nav__header-btn {{burgerMenuOpen ? 'open' : ''}}"
                        type="button"></button>
                <img alt="logo de galec iam" class="c-nav__header-logo" src="/assets/img/logo/galec-iam-logo.svg">
            </div>
            <ul class="c-nav__items {{burgerMenuOpen ? 'open' : 'close'}}">
                <li class="c-nav__title">Leclerc balbec les bains</li>
                <li *ngFor="let route of routes" class="c-nav__item" routerLinkActive="c-nav__item--active">
                    <a [routerLink]="['fr/app/' + route.path]" class="c-nav__link">
                        <div class="c-nav__item-wrapper">
                            <div class="c-nav__item-logo">
                                <i class="icon-{{route.data.icon}}"></i>
                            </div>
                            <div class="c-nav__item-txt">{{ route.data.title }}</div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <button (click)="logout()" class="c-nav__bottom-item c-nav__item" type="button">
            <div class="c-nav__item-wrapper">
                <div class="c-nav__item-logo">
                    <i class="icon-deconnexion"></i>
                </div>
                <div class="c-nav__item-txt">Déconnexion</div>
            </div>
        </button>
    </nav>
</header>
<!-- end mobile nav -->

<!-- header connection -->
<div *ngIf="!loading">
    <div *hasRole="['central', 'national']" class="c-header-connection connected">
        <img alt="logo de galec iam" class="c-header-connection__logo" src="/assets/img/logo/galec-iam-logo.svg">
        <a (click)="goBackAdmin()" *ngIf="showProfileBtn" class="c-header-connection__home"
           title="Retourner à l'accueil du Profil {{role}}">
            <i class="icon-fleche_gauche"></i>Retourner à l'accueil du Profil {{ role }}</a>
        <span class="header-centrale-name d-none d-lg-block">{{ name }}</span>
        <span class="c-header-connection__btn">
        <span class="c-header-connection__identity">{{ user.username }}</span>
        <i (click)="logout()" class="icon-deconnexion"></i>
    </span>
    </div>
    <!-- end header connection -->
    <div *hasRole="['magasin']" class="c-header-connection d-none d-lg-flex">
        <button (click)="logout()" class="c-header-connection__btn d-none d-lg-flex" type="button">
            <i class="icon-deconnexion"></i>
        </button>
    </div>

    <main class="website__content container">
        <app-change-lang></app-change-lang>
        <div *ngIf="delegation" class="row">
            <div class="col-12">
                <div class="c-delegation">
                    délégation pour : <span class="c-delegation__town">{{ delegation.toString() }}</span> <span
                        class="c-delegation__nbr"> ({{ delegation.id }})</span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- desktop nav -->
            <div *ngIf="checkIsAdmin" class="d-none d-lg-block position-relative col-lg-1">
                <nav class="c-nav">
                    <div class="c-nav__header">
                        <img alt="logo de galec iam" class="c-nav__header-logo"
                             src="/assets/img/logo/galec-iam-logo.svg">
                    </div>
                    <ul class="c-nav__items">
                        <li *ngFor="let route of routes" class="c-nav__item" routerLinkActive="c-nav__item--active">
                            <a [routerLink]="currentLang + '/app/' + route.path" class="c-nav__link">
                                <div class="c-nav__item-wrapper">
                                    <div class="c-nav__item-logo">
                                        <i class="icon-{{route.data.icon}}"></i>
                                    </div>
                                    <div class="c-nav__item-txt">{{ route.data.title }}</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <!-- end desktop nav -->
            <div class="col-12 {{!checkIsAdmin ? 'col-lg-12': 'col-lg-11'}}">
                <router-outlet class="router-outlet"></router-outlet>
            </div>
        </div>
    </main>
</div>
