<div *ngIf="!finish" class="c-card c-card--notitle c-create-news">
    <div class="c-card__title mb-4">
        {{ TEXTE.TITRE | async }}
    </div>

    <ul class="galec-etapier mt-2 mt-lg-3 pb-5 pb-lg-0">
        <li class="galec-etapier__item {{activeClass(1)}}">
            <span class="galec-etapier__number">1</span>
            <span class="galec-etapier__titre">{{ TEXTE.ETAPE_UN | async }}</span>
        </li>
        <li class="galec-etapier__item {{activeClass(2)}}">
            <span class="galec-etapier__number">2</span>
            <span class="galec-etapier__titre">{{ TEXTE.ETAPE_DEUX | async }}</span>
        </li>
        <li class="galec-etapier__item {{activeClass(3)}}">
            <span class="galec-etapier__number">3</span>
            <span class="galec-etapier__titre">{{ TEXTE.ETAPE_TROIS | async }}</span>
        </li>
        <li *ngIf="isCentral" class="galec-etapier__item {{activeClass(4)}}"><span
                class="galec-etapier__number">4</span><span class="galec-etapier__titre">{{ TEXTE.ETAPE_QUATRE | async }}</span>
        </li>
    </ul>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading">
        <form [formGroup]="form" class="form-step">
            <div *ngIf="step === 1">
                <div class="c-card__infos">{{ TEXTE.CHOISIR_FORMAT | async }}</div>
                <div class="c-create-news__format">
                    <div class="c-create-news__format--new">
                        <div class="c-create-news__title">{{ TEXTE.NOUVEAU | async }}</div>
                        <div class="c-create-news__desc">{{ TEXTE.CREER_AZ | async }}</div>
                        <!-- Slider desktop only -->
                        <div class="d-none d-md-block">
                            <ul class="c-create-news__list c-slider__content">
                                <ng-container *ngFor="let type of types | keyvalue: sort">
                                    <li (click)="setFormat(type.key)"
                                        class="c-create-news__list--item {{format.typeActualite === type.key ? 'selected' : null}}">
                                        <div><img alt="" src="{{type.value.image}}"/></div>
                                        <span> {{ type.value.label }}</span>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <!-- Slider mobile only -->
                        <div class="c-slider--all d-md-none position-relative overflow-hidden">
                            <button (click)="owlCarTypes.prev()" class="c-slider__btn--prev" type="button"><i
                                    class="icon-fleche_gauche"></i></button>
                            <button (click)="owlCarTypes.next()" class="c-slider__btn--next" type="button"><i
                                    class="icon-fleche_droite"></i></button>
                            <owl-carousel-o #owlCarTypes [options]="typeSliderConfig"
                                            class="c-create-news__list c-slider__content">
                                <ng-template *ngFor="let type of types | keyvalue: sort" carouselSlide>
                                    <div (click)="setFormat(type.key)"
                                         class="c-create-news__list--item {{format.typeActualite === type.key ? 'selected' : null}}">
                                        <div>
                                            <img alt="" src="{{type.value.image}}"/>
                                        </div>
                                        <span> {{ type.value.label }}</span>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                    <div *ngIf="!isModel && models?.size > 0" class="c-create-news__format--template">
                        <div>
                            <div class="c-create-news__title">{{ TEXTE.MODELES | async }}</div>
                            <div class="c-create-news__desc">{{ TEXTE.CREER_EXISTANT | async }}</div>
                            <div class="c-slider--all">
                                <!-- Slider mobile only -->
                                <div class="position-relative overflow-hidden {{models.size > 3 ? 'show-navigation': ''}}">
                                    <button (click)="owlCarModelMobile.prev()" class="c-slider__btn--prev" type="button">
                                        <i class="icon-fleche_gauche"></i>
                                    </button>
                                    <button (click)="owlCarModelMobile.next()" class="c-slider__btn--next" type="button">
                                        <i class="icon-fleche_droite"></i>
                                    </button>
                                    <owl-carousel-o #owlCarModelMobile
                                                    [options]="modelSliderConfig"
                                                    class="c-create-news__list c-slider__content">
                                        <ng-template *ngFor="let model of models | keyvalue: sort" carouselSlide>
                                            <div (click)="setFormat(model.value, true)" class="c-create-news__list--item {{format.modelActualite == model.value.id_actualite ? 'selected' : null}}">
                                                <div class="position-relative">
                                                    <galec-actualite-thumbnail [actualite]="model.value" [showTitle]="true"></galec-actualite-thumbnail>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectTypeOrModel" class="c-asterisk">
                    * {{ TEXTE.SELECTIONNER_MODELE | async }}
                </div>
                <div class="c-create-news__btn mt-lg-4">
                    <button (click)="goBack()" class="c-btn c-create-news__btn--quit c-btn--blue" type="button">
                        {{ TEXTE.QUITTER | async }}
                    </button>
                    <button (click)="next()" class="c-btn c-create-news__btn--next ml-lg-4" type="button">
                        {{ TEXTE.SUIVANT | async }}
                        <i class="icon-fleche_droite"></i>
                    </button>
                </div>
            </div>
            <div *ngIf="step === 2">
                <div *ngIf="!isModel" class="c-create-news__dates d-inline mt-4">
                    <div class="row mt-4">
                        <div class="col-2">
                            <div class="  {{edit?.id_actualite>0 && !edit.draft ? 'disabled' : null}}">
                                <label class="c-card__small-title" for="date_debut">{{ TEXTE.DATE.DEBUT | async }}<sup>*</sup></label>
                                <div class="date-picker-start date-picker" controlErrorContainer>
                                    <input [matDatepickerFilter]="filterMaxActualiteByPeriod"
                                           [matDatepicker]="date_debut"
                                           class="c-btn galec-calendar mt-2 w-100 {{edit?.id_actualite>0 && !edit.draft ? 'disabled' : null}}"
                                           disabled
                                           formControlName="date_debut"
                                           id="date_debut" matInput>
                                    <mat-datepicker-toggle [for]="date_debut" class="button-calendar" matSuffix>
                                        <i class="icon-calendrier" matDatepickerToggleIcon></i>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #date_debut [disabled]="edit.id_actualite>0 && !edit.draft" class="position-relative p-0"></mat-datepicker>
                                </div>
                            </div>

                        </div>
                        <div class="col-2">
                            <div class="c-create-news__dates--start-hour mt-3 mt-lg-0">
                                <label class="c-card__small-title" for="heure_debut">{{ TEXTE.HEURE.DEBUT | async }}<sup>*</sup></label>
                                <div class="ngx-timepicker-field-example timepicker__wrapper" controlErrorContainer>
                                    <input [disableClick]="true"
                                           [disabled]="edit?.id_actualite>0" [format]="24"
                                           [ngxTimepicker]="heure_debut"
                                           class="c-btn galec-hour mt-2 w-100 {{edit?.id_actualite>0 && !edit.draft ? 'disabled' : null}}"
                                           formControlName="heure_debut"
                                           id="heure_debut" type="time">
                                    <ngx-material-timepicker-toggle [disabled]="edit?.id_actualite>0 && !edit.draft" [for]="heure_debut"></ngx-material-timepicker-toggle>
                                    <ngx-material-timepicker #heure_debut></ngx-material-timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="mt-lg-0">
                                <label class="c-card__small-title" for="date_fin">{{ TEXTE.DATE.FIN | async }}</label>
                                <div class="date-picker-end date-picker" controlErrorContainer>
                                    <input [matDatepickerFilter]="filterMaxActualiteByPeriod" [matDatepicker]="date_fin"
                                           class="c-btn galec-calendar mt-2 w-100 {{edit?.id_actualite>0 && !edit.draft ? 'disabled' : null}}"
                                           disabled
                                           formControlName="date_fin"
                                           id="date_fin" matInput>
                                    <mat-datepicker-toggle [for]="date_fin" class="button-calendar" matSuffix>
                                        <i class="icon-calendrier" matDatepickerToggleIcon></i>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #date_fin disabled="false"></mat-datepicker>
                                    <i (click)="clearDate('date_fin')" *ngIf="form.value.date_fin" class="icon-fermer"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="c-create-news__dates--start-hour mt-3 mt-lg-0">
                                <label class="c-card__small-title" for="heure_fin">{{ TEXTE.HEURE.FIN | async }}</label>
                                <div class="ngx-timepicker-field-example timepicker__wrapper" controlErrorContainer>
                                    <input [disableClick]="true" [format]="24" [ngxTimepicker]="heure_fin"
                                           class="c-btn galec-hour mt-2 w-100" formControlName="heure_fin"
                                           id="heure_fin"
                                           type="time">
                                    <ngx-material-timepicker-toggle [for]="heure_fin"></ngx-material-timepicker-toggle>
                                    <ngx-material-timepicker #heure_fin></ngx-material-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p class="msg-help">{{ TEXTE.MESSAGE_MAX_ACTU | async }} ({{ MAX_ITEMS }})</p>
                        </div>
                    </div>

                </div>

                <div *ngIf="!isModel && !isCentral">
                    <div class="c-title mt-5">{{ TEXTE.CONCERNES_PAR_ACTU | async }}</div>
                    <div class="c-form">
                        <div class="row">
                            <div class="col-12 col-lg-3 mt-2 c-form__check--item">
                                <input (click)="checkAll($event)" formControlName="Allconcept_e" id="Allconcept_e" type="checkbox">
                                <label aria-describedby="label" for="Allconcept_e">{{ TEXTE.TOUS_LES_POINTS_VENTES | async }}</label>
                            </div>
                            <ul class="col c-form__check--item mt-2" controlErrorContainer formGroupName="express">
                                <li *ngFor="let a_express of express; let i=index" class="col-12 col-lg-3 c-form__check--item">
                                    <input (click)="expressChecked($event, a_express)" formControlName="{{a_express.id}}" id="concept-{{a_express.id}}" type="checkbox">
                                    <label #tooltip="matTooltip" [matTooltipPosition]="'below'"
                                           aria-describedby="label" for="concept-{{a_express.id}}"
                                           matTooltip="{{a_express.activitecommerciale.libelle }} ({{ a_express.Triptych }})"
                                           matTooltipClass="galec-tooltip">{{ a_express.libelle }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="c-card__infos mb-3">
                    {{ TEXTE.CLIQUER_MODIFIER | async }} :
                </div>
                <div class="form-group">
                    <input class="form-control"
                           formControlName="titre"
                           placeholder="{{ TEXTE.TITRE_ACTU.MESSAGE | async }} {{ TEXTE.TITRE_ACTU.MESSAGE | async }}"
                           type="text">
                </div>
                <div *ngIf="format.type !== 'text'" class="form-group">
                    <input class="form-control" formControlName="lien" id="lien" placeholder="{{ TEXTE.LIEN_IMAGE | async }}" type="text">
                </div>
                <div class="c-create-news__content">
                    <div (click)="showImage()" *ngIf="format.type !== 'text'" class="{{format.type === 'textImage' ? 'c-create-news__content--left-zone' : (form.value.imageBase64 ? null: 'c-create-news__content--full-img-zone')}}">
                        <div controlErrorContainer>
                            <label *ngIf="!form.value.image" class="label-file">
                                <span>{{ TEXTE.UPLOAD_IMAGE.MESSAGE | async }}<br/>{{ TEXTE.UPLOAD_IMAGE.TAILLE | async }}</span>
                                <i class="icon-img"></i>
                            </label>
                            <img *ngIf="form.controls.imageBase64.value" [src]="form.controls.imageBase64.value" class="img-actualite">
                            <textarea class="d-none" formControlName="image" id="image"></textarea>
                        </div>
                    </div>
                    <div *ngIf="format.type !== 'image'"
                         class="{{format.type === 'textImage' ? 'c-create-news__content--right-zone' : 'c-create-news__content--full-txt-zone'}}">
                        <quill-editor (onEditorCreated)="getEditorInstance($event)" class="quill-input"
                                      customToolbarPosition="bottom"
                                      formControlName="texte" format="html" placeholder="{{ TEXTE.AJOUT_TEXTE | async }}"
                                      theme="bubble">
                            <div quill-editor-toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold"></button>
                                    <button class="ql-italic"></button>
                                    <button class="ql-link"></button>
                                </span>
                            </div>
                        </quill-editor>
                    </div>
                </div>

                <div class="c-create-news__btn mt-3 mt-lg-4">
                    <button (click)="goBack()" *ngIf="edit.id_actualite" class="c-btn c-create-news__btn--quit c-btn--blue" type="button">
                        {{ TEXTE.QUITTER | async }}
                    </button>
                    <button (click)="previous()" *ngIf="!edit.id_actualite" class="c-btn c-create-news__btn--prev picto-first" type="button">
                        <i class="icon-fleche_gauche"></i>
                        <span>{{ TEXTE.PRECEDENT | async }}</span>
                    </button>
                    <button (click)="next()" class="c-btn c-create-news__btn--next ml-lg-4" type="submit">
                        {{ TEXTE.SUIVANT | async }}
                        <i class="icon-fleche_droite"></i>
                    </button>
                </div>
            </div>
            <div *ngIf="step === 3">
                <div class="c-card__infos">{{ TEXTE.DERNIERE_VERIF | async }}</div>
                <h2 class="c-create-news__title">{{ form.value.titre }}</h2>
                <div class="c-create-news__content">
                    <div *ngIf="format.type !== 'text'" class="{{format.type === 'textImage' ? 'c-create-news__content--left-zone' : null}}">
                        <a *ngIf="form.value.lien" href="{{form.value.lien}}">
                            <img *ngIf="form.value.imageBase64" alt="" class="img-actualite" src="{{form.value.imageBase64}}">
                        </a>
                        <img *ngIf="!form.value.lien" alt="" class="img-actualite" src="{{form.value.imageBase64}}">
                    </div>
                    <div *ngIf="format.type !== 'image'" class="{{format.type === 'textImage' ? 'c-create-news__content--right-zone' : 'c-create-news__content--full-txt-zone'}}">
                        <div [innerHTML]="form.value.texte"></div>
                    </div>
                </div>
                <div class="c-create-news__btn mt-3 mt-lg-4">
                    <button (click)="previous()" class="c-btn c-create-news__btn--prev picto-first" type="button">
                        <i class="icon-fleche_gauche"></i>
                        <span>{{ TEXTE.PRECEDENT | async }}</span>
                    </button>
                    <button (click)="submit()" *ngIf="!isCentral" class="c-btn c-create-news__btn--next ml-lg-4" type="submit">
                        {{ TEXTE.CONFIRMER | async }}
                        <i class="icon-fleche_droite"></i>
                    </button>
                    <button (click)="next()" *ngIf="isCentral" class="c-btn c-create-news__btn--next ml-lg-4" type="submit">
                        {{ TEXTE.SUIVANT | async }}
                        <i class="icon-fleche_droite"></i>
                    </button>
                </div>
            </div>
            <div *ngIf="step === 4 && isCentral">
                <div class="c-card__infos">{{ 'CATEGORIES.ACTUALITES.HYPER_SUPER_CONCERNES' | translate}}</div>
                <div class="mt-3">
                    <div class="c-form">
                        <div class="row">
                            <div class="col-12 col-lg-3 mt-2 c-form__check--item">
                                <input (click)="checkAll($event)" formControlName="Allconcepts" id="Allconcepts"
                                       type="checkbox">
                                <label aria-describedby="label" for="Allconcepts">{{ 'CATEGORIES.ACTUALITES.TOUS_HYPER_SUPER' | translate }}</label>
                            </div>
                        </div>
                        <div formGroupName="pointventes">
                            <ul class="row c-form__check mt-2" controlErrorContainer>
                                <li *ngFor="let pointVente of pointVentes; let i=index" class="col-12 col-lg-3 c-form__check--item">
                                    <input (click)="conceptChecked($event)" formControlName="{{pointVente.id}}" id="concept-{{pointVente.id}}" type="checkbox">
                                    <label #tooltip="matTooltip" [matTooltipPosition]="'below'" aria-describedby="label" for="concept-{{pointVente.id}}" matTooltip="{{pointVente.activitecommerciale.libelle }} ({{ pointVente.Triptych }})" matTooltipClass="galec-tooltip">
                                        {{ pointVente.libelle }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="c-create-news__btn mt-3 mt-lg-4">
                    <button (click)="previous()" class="c-btn c-create-news__btn--prev picto-first" type="button">
                        <i class="icon-fleche_gauche"></i>
                        <span>{{ TEXTE.PRECEDENT | async }}</span>
                    </button>
                    <button (click)="submit()" class="c-btn c-create-news__btn--next ml-lg-4" type="submit">
                        {{ TEXTE.CONFIRMER | async }}
                        <i class="icon-fleche_droite"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="finish" class="c-card c-card--notitle c-create-news">
    <div class="c-card__title mb-4">{{ TEXTE.CONFIRMATION | async }}</div>

    <div class="c-create-news__confirm">
        <i class="icon-valider"></i>
        <p *ngIf="!recap.draft">
            <span *ngIf="edit.id_actualite">{{ TEXTE.MODIF_VALIDER | async }}</span>
            <span *ngIf="!edit.id_actualite">
                {{ TEXTE.CONFIRM_SAISI | async }} « {{ recap.title }} ».<br/>
                <span *ngIf="recap.date">{{ TEXTE.DATE_VISIBLE.PART_1 | async }} {{ recap.date }} {{ TEXTE.DATE_VISIBLE.PART_2 | async }} {{ recap.time }}</span>.
            </span>
        </p>
        <p *ngIf="recap.draft">
            {{ TEXTE.ACTUALITE_ENREGISTRER.PART_1 | async }} <b>{{ recap.title }}</b> {{ TEXTE.ACTUALITE_ENREGISTRER.PART_2 | async }}
        </p>
    </div>

    <div class="c-create-news__btn--confirm  mt-lg-4">
        <div>
            <a [routerLink]="['/' + currentLang + '/app/home']" class="c-btn c-btn--blue" title="">
                {{ TEXTE.ALLER_ACCUEIL | async }}
            </a>
            <button (click)="goBack()" class="c-btn ml-lg-4" title="">
                {{ TEXTE.VOIR | async }}
            </button>
        </div>
    </div>

</div>
