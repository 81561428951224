import {Component, effect, OnInit, ViewEncapsulation} from "@angular/core";
import {LangService} from "@galec/services/global/lang.service";
import {LangInterface} from "@interfaces/lang.interface";
import {LangEnum} from "@galec/helpers/enums/lang.enum";

@Component({
    selector: 'app-change-lang',
    templateUrl: './change-lang.component.html',
    styleUrls: ['./change-lang.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChangeLangComponent implements OnInit {

    public currentLang: string;
    public allLangs: LangInterface[];

    constructor(
        private readonly langService: LangService
    ) {
        effect((): void => {
            this.currentLang = this.langService.getCurrentLang();
        });
    }

    ngOnInit(): void {
        this.currentLang = this.langService.getCurrentLang();
        this.getAllLangs();
    }

    private getAllLangs(): void {
        this.allLangs = this.langService.getAllLangs();
        console.log(this.allLangs);
    }

    public isSelectedLang(lang: LangInterface): boolean {
        return (lang.enum as string) === this.currentLang;
    }

    public changeLang(lang: string): void {
        console.log(lang);
        this.langService.changeLang(lang as LangEnum);
    }
}
