<!--<select (change)="changeLanguage($event.target.value)">-->
<!--    <option *ngFor="let lang of allLangs" [value]="lang.enum" [selected]="isSelectedLang(lang)">-->
<!--        {{lang.enum}}-->
<!--        <img src="{{lang.flag}}" alt="{{lang.name}}" />-->
<!--        <img [ngSrc]="lang.flag" alt="{{lang.name}}" width="20" height="20" loading="lazy" />-->
<!--    </option>-->
<!--</select>-->

<ul class="lang-list">
    <li *ngFor="let lang of allLangs">
        <button (click)="changeLang(lang.enum)">
            <img [src]="lang.flag" alt="{{lang.name}}" [ngClass]="isSelectedLang(lang) ? 'active-lang' : ''" loading="lazy" />
        </button>
    </li>
</ul>