<div class="c-error-page galec-404">
    <app-change-lang></app-change-lang>
    <div class="c-card">
        <img src="../assets/img/logo/galec-iam-logo-bleu.svg" class="w-100" alt="IAM Logo"/>
        <div class="c-error__title mt-5">{{ 'CATEGORIES.PAGE_404.TITRE' | translate }}</div>
        <div class="c-error__desc">{{ 'CATEGORIES.PAGE_404.PAGE_INTROUVABLE' | translate }}</div>
        <div class="c-card__small-title mt-3">{{ 'CATEGORIES.PAGE_404.LIEN_INCORRECT' | translate }}</div>
        <button (click)="goBack()" class="c-btn mt-4">
            {{ 'UTILS.RETOUR_PAGE_ACCUEIL' | translate }}
            <i class="icon-fleche_droite"></i>
        </button>
        <button (click)="logout()" type="button" class="c-btn mt-4">
            {{ 'UTILS.DECONNECTER' | translate }}
        </button>
    </div>
</div>
