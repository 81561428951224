import {effect, Injectable, OnInit, WritableSignal} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LangEnum} from "@galec/helpers/enums/lang.enum";
import {TranslateService} from "@ngx-translate/core";
import { signal } from '@angular/core';
import {Observable} from "rxjs";
import {LangInterface} from "@interfaces/lang.interface";

@Injectable({ providedIn: 'root' })
export class LangService implements OnInit {
    public defaultLang: LangEnum = LangEnum.FR;
    private currentLang: WritableSignal<LangEnum> = signal(this.defaultLang);

    private readonly flagsFolder: string = "/assets/img/flags";

    private readonly allLangs: LangInterface[] = [
        { name: "Français", enum: LangEnum.FR, flag: `${this.flagsFolder}/france-flag.png` },
        { name: "Español", enum: LangEnum.ES, flag: `${this.flagsFolder}/spain-flag.png` },
        { name: "Português", enum: LangEnum.PT, flag: `${this.flagsFolder}/portugal-flag.png` },
        { name: "Slovenščina", enum: LangEnum.SL, flag: `${this.flagsFolder}/slovenia-flag.png` },
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
    ) {
        effect((): void => {
            console.log('Changement de langue :', this.currentLang());
            this.applyLang();
        });
    }

    ngOnInit(): void {
        this.detectLang();
        console.log(this.currentLang());
    }

    public getCurrentLang(): string {
        return (this.currentLang() as string);
    }

    public applyLang(): void {
        console.log(this.currentLang());
        if (Object.values(LangEnum).includes(this.currentLang())) {
            this.translate.use(this.currentLang());
            // this.navigate(this.getCurrentRoute());
            // localStorage.setItem('language', this.currentLang());
        }
    }

    public changeLang(lang: LangEnum): void {
        if (this.isAvailableLang(lang)) {
            const currentRoute: string = this.getCurrentRoute();
            this.router.navigate([`/${lang}/${currentRoute}`], { replaceUrl: true });
        } else {
            console.error(`La langue ${lang as string} n'est pas reconnu par Bim-Iam!`)
        }
    }

    public detectLang(): void {
        this.route.firstChild?.paramMap.subscribe(params => {
            console.log(params);
            const lang: LangEnum = params.get("lang") as LangEnum;
            console.log(lang);
            if (this.isAvailableLang(lang)) {
                console.log('changement');
                this.currentLang.set(lang);
            } else {
                this.redirectToValidLang();
            }
        });
    }

    public getTranslate(message: string): Observable<string> {
        return this.translate.get(message);
    }

    private isAvailableLang(lang: LangEnum): boolean {
        return Object.values(LangEnum).includes(lang);
    }

    public navigate(url: string): void {
        console.log(url);
        this.router.navigate([`/${this.currentLang()}/${url}`], { replaceUrl: true });
    }

    public redirectToValidLang(): void {
        const currentRoute: string = this.getCurrentRoute();
        console.log(currentRoute);
        this.router.navigate([`/${this.defaultLang}/${currentRoute}`], { replaceUrl: true });
    }

    private getCurrentRoute(): string {
        return this.router.url.split('/').slice(2).join('/');
    }

    public getAllLangs(): LangInterface[] {
        return this.allLangs;
    }
}
