import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppAuthGuard} from '@galec/helpers/guards/auth.guard';
import {NotfoundComponent} from '@galec/modules/front/components';
import {MaintenanceGuard} from "@galec/helpers/guards/maintenance.guard";

// App routes.
const routes: Routes = [
    {
        path: ':lang/app',
        loadChildren: () => import('./modules/back/back.module').then(module => module.BackModule),
        canActivate: [MaintenanceGuard, AppAuthGuard]
    },
    {path: '', pathMatch: 'full', redirectTo: 'fr/app/home'},
    {
        path: ':lang', loadChildren: () => import('./modules/front/front.module').then(module => module.FrontModule)
    },
    {path: ':lang/404', component: NotfoundComponent},
    {path: '**', pathMatch: 'full', redirectTo: 'fr/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AppAuthGuard]
})
export class AppRoutingModule {
}
