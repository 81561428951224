// Contrib declarations.
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// App declarations.
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@galec/environments/environment';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { UserAuthenticationService } from '@galec/services';
import { BackContainerComponent } from '@galec/modules/back/components';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from '@galec/modules/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BackModule } from '@galec/modules/back/back.module';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ZonechalandiseRepository } from './repositories/zonechalandise.gql';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
// Key cloak service instance.
const keycloakService: KeycloakService = new KeycloakService();

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        // ToastrModule.forRoot(),
        MatSnackBarModule,
        KeycloakAngularModule,
        MatDialogModule,
        MatButtonModule,
        BackModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent,
        BackContainerComponent,
    ],
    providers: [
        {
            provide: KeycloakService,
            useValue: keycloakService
        },
        {
            provide: ZonechalandiseRepository,

        }
    ],
})

export class AppModule implements DoBootstrap {
    constructor(private userAuthenticationService: UserAuthenticationService) {
    }

    async ngDoBootstrap(app: ApplicationRef) {
        const { sso } = environment;

        // Check if user is redirected to init keycloak service.
        if (this.userAuthenticationService.isRedirected && !environment.maintenance) {
            try {
                await keycloakService.init({
                    config: sso,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: false,
                    },
                    enableBearerInterceptor: true,
                    loadUserProfileAtStartUp: false,
                },
                );
            } catch (error) {
                console.error('Keycloak init failed', error);
            }
            // Set user data in local storage & observable.
            this.userAuthenticationService?.getSsoConnectedUser();
            this.userAuthenticationService?.currentUser?.subscribe(user => {
                if ((window as any).gtag && user?.googleAnalyticsId) {
                    (window as any).gtag('config', environment.google_analytics_code, {
                        user_id: user.googleAnalyticsId
                    });
                    (window as any).gtag('set', 'user_properties', {
                        userId: user.googleAnalyticsId,
                        userName: user.username,
                        role: user.role !== null ? user.role : 'reader',
                    });
                }
            });
        }
        // Bootstrap component.
        app.bootstrap(AppComponent);
    }
}

