import {gql, Apollo} from 'apollo-angular';
import {DocumentNode, FetchResult} from '@apollo/client/core';

import {Injectable} from '@angular/core';

import {combineLatest, Observable} from 'rxjs';

import {BaseRepository} from '@galec/repositories/baseRepository';
import {UserAuthenticationService} from "@galec/services/user/user-authentication.service";
import {map} from "rxjs/operators";
import {Actualite} from "@models/actualite.model";
import {PointVente} from "@models/pointVente.model";
import {PointventeActualite} from "@models/pointventeActualite.model";
import * as _ from 'lodash';

@Injectable()
/**
 * Actualite repository service.
 */
export class ActualiteRepository extends BaseRepository {

    protected DELETE_QUERY: DocumentNode = DeleteServicePointVenteGql;
    protected INSERT_QUERY: DocumentNode = INSERT_ACTUALITE;
    protected LIST_QUERY: DocumentNode;
    protected UPDATE_QUERY: DocumentNode = UPDATE_ACTUALITE;

    constructor(protected apollo: Apollo, protected userAuthenticationService: UserAuthenticationService) {
        super();
        this.init();
    }

    /**
     * @inheritDoc
     */
    public insert(actualite: Actualite): Observable<FetchResult<{}>> {
        const variables: any = {objects: [actualite]};
        if (actualite.id_actualite) {
            variables.id_actualite = actualite.id_actualite;
        }
        return super.insert(variables);
    }

    /**
     * @inheritDoc
     */
    public update(actualite: Actualite): Observable<FetchResult<{}>> {
        const pointvente_actualite = actualite.pointvente_actualites.data;
        delete actualite.pointvente_actualites;
        return super.update({object: actualite, id_actualite: actualite.id_actualite, pointvente_actualite});
    }

    /**
     * @inheritDoc
     */
    public select(variables?: any): Observable<any> {
        const centrale = variables.centrale ?? false;
        delete variables.centrale;
        if (variables.code_panonceau == null){
            return combineLatest([
                this.watchAndSubscribe(ACTUALITE_CENTRALE_ONLINE_Query, ACTUALITE_CENTRALE_ONLINE_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_CENTRALE_PROGRAMMED_Query, ACTUALITE_CENTRALE_PROGRAMMED_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_CENTRALE_DRAFT_Query, ACTUALITE_CENTRALE_DRAFT_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_CENTRALE_PASSED_Query, ACTUALITE_CENTRALE_PASSED_Subscription, variables),
            ])
                .pipe(map(res => {
                    return {
                        online      : this.formatActualite(res[0], centrale),
                        programmed  : this.formatActualite(res[1], centrale),
                        draft       : this.formatActualite(res[2], centrale),
                        passed      : this.formatActualite(res[3], centrale),
                    };
                }));
        } else {
            return combineLatest([
                this.watchAndSubscribe(ACTUALITE_ONLINE_Query, ACTUALITE_ONLINE_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_PROGRAMMED_Query, ACTUALITE_PROGRAMMED_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_DRAFT_Query, ACTUALITE_DRAFT_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_PASSED_Query, ACTUALITE_PASSED_Subscription, variables),
            ])
                .pipe(map(res => {
                    return {
                        online      : this.formatActualite(res[0], centrale),
                        programmed  : this.formatActualite(res[1], centrale),
                        draft       : this.formatActualite(res[2], centrale),
                        passed      : this.formatActualite(res[3], centrale),
                    };
                }));
        }
    }

    public listOfActualiteWithCodepanonceau(variables?: any): Observable<any> {
        const centrale = variables.centrale ?? false;
        delete variables.centrale;
        if (variables.code_panonceau == null){
            return combineLatest([
                this.watchAndSubscribe(ACTUALITE_CENTRALE_ONLINE_Query, ACTUALITE_CENTRALE_ONLINE_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_CENTRALE_PROGRAMMED_Query, ACTUALITE_CENTRALE_PROGRAMMED_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_CENTRALE_DRAFT_Query, ACTUALITE_CENTRALE_DRAFT_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_CENTRALE_PASSED_Query, ACTUALITE_CENTRALE_PASSED_Subscription, variables),
            ])
                .pipe(map(res => {
                    return {
                        online      : this.formatActualite(res[0], centrale),
                        programmed  : this.formatActualite(res[1], centrale),
                        draft       : this.formatActualite(res[2], centrale),
                        passed      : this.formatActualite(res[3], centrale),
                    };
                }));
        } else {
            return combineLatest([
                this.watchAndSubscribe(ACTUALITE_ONLINE_Query, ACTUALITE_ONLINE_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_PROGRAMMED_Query, ACTUALITE_PROGRAMMED_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_DRAFT_Query, ACTUALITE_DRAFT_Subscription, variables),
                this.watchAndSubscribe(ACTUALITE_PASSED_Query, ACTUALITE_PASSED_Subscription, variables),
            ])
                .pipe(map(res => {
                    return {
                        online      : this.formatActualite(res[0], centrale),
                        programmed  : this.formatActualite(res[1], centrale),
                        draft       : this.formatActualite(res[2], centrale),
                        passed      : this.formatActualite(res[3], centrale),
                    };
                }));
        }
    }

    public models(): Observable<Map<string, Actualite>> {
        return this.watchAndSubscribe(MODELS_Query, MODELS_Subscription, {})
            .pipe(map(this.formatModelOrActualiteCentrale));
    }

    public actualitesCentrale(code_panonceau_centrale: string): Observable<Map<string, Actualite>> {
        return this.watchAndSubscribe(CENTRALE_ACTUALITE_Query, CENTRALE_ACTUALITE_Subscription, {code_panonceau_centrale})
            .pipe(map(this.formatModelOrActualiteCentrale));
    }


    private formatActualite(result: any, centrale = false): Actualite[] {
        let actualites = [];
        result.data?.pointvente_actualite?.forEach((value: any) => {
            if (value.actualite) {
                const actualite = new Actualite(value.actualite);
                actualite.ordre = value.ordre ?? actualite.ordre;
                if (centrale && actualite.actu_centrale) {
                    actualites.push(actualite);
                } else if (!centrale) {
                    actualites.push(actualite);
                }
            }
        });
        actualites = _.orderBy(actualites, ['ordre'], ['asc']);
        return actualites;
    }


    private formatModelOrActualiteCentrale(result: any): Map<string, Actualite> {
        const actualites: Map<string, Actualite> = new Map<string, Actualite>();
        result.data?.actualite?.forEach((value: any) => {
            const actualite = new Actualite(value);
            actualites.set(actualite.id_actualite.toString(), actualite);
        });
        return actualites;
    }


    findOne(id: any): Observable<Actualite> {
        return this.queryHandler(FindOneQuery, {id}).pipe(map((result: any) => {
            let actualite = new Actualite({});
            if (result.data?.actualite[0]) {
                actualite = new Actualite(result.data?.actualite[0]);
            }
            return actualite;
        }));
    }

    public deleteModel(id): Observable<FetchResult<{}>> {
        return this.mutationHandler(DELETE_MODEL, {id_actualite: id});
    }


    /**
     * @inheritDoc
     */
    public saveOrders(pointvente_actualites: PointventeActualite[]): Observable<FetchResult<{}>> {
        return super.mutationHandler(INSERT_ACTUALITE_ORDER, {objects: pointvente_actualites});
    }

    public listOfActualitePublished(id_base_adherent: string): Observable<Actualite[]> {
        return this.watchAndSubscribe(ACTUALITE_PUBLISHED_Query, ACTUALITE_PUBLISHED_Subscription, {id_base_adherent})
            .pipe(map(e => this.formatActualite(e)));
    }
}


const ACTUALITE_FIELDS = `
actu_centrale
date_debut
date_fin
id_actualite
image
page_detail
texte
titre
draft
model
lien
type
pointvente_actualites {
  id_actualite
  id_base_adherent
  ordre
}
`;


/**
 * Insert actualite repository.
 */
const INSERT_ACTUALITE = gql`
    mutation InsertActualite($objects: [actualite_insert_input!]!, $id_actualite: Int = -20) {
        delete_pointvente_actualite(where: {id_actualite: {_eq: $id_actualite}}) {
            affected_rows
        }
        insert_actualite(objects: $objects,
            on_conflict: {constraint: actualite_pkey, update_columns: [actu_centrale, date_debut, date_fin, image,
                page_detail, texte, titre, draft, model, lien]}
        ) {
            affected_rows
        }
    }
`;

/**
 * Update actualite repository.
 */
const UPDATE_ACTUALITE = gql`
    mutation UpdateActualite($object: actualite_set_input!, $id_actualite: Int!,
        $pointvente_actualite: [pointvente_actualite_insert_input!]!) {
        update_actualite(where: {id_actualite: {_eq: $id_actualite}}, _set: $object) {
            affected_rows
        }
        delete_pointvente_actualite(where: {id_actualite: {_eq: $id_actualite}}) {
            affected_rows
        }
        insert_pointvente_actualite(objects: $pointvente_actualite,
            on_conflict: {constraint: pointvente_actualite_pk, update_columns: [ordre]}
        ) {
            affected_rows
        }
    }
`;

/**
 * Insert actualite ordre repository.
 */
const INSERT_ACTUALITE_ORDER = gql`
    mutation InsertActualite($objects: [pointvente_actualite_insert_input!]!) {
        insert_pointvente_actualite(objects: $objects,
            on_conflict: {constraint: pointvente_actualite_pk, update_columns: [ordre]}
        ) {
            affected_rows
        }
    }
`;


/**
 * Insert horaire exceptionnel repository.
 */
const DeleteServicePointVenteGql = gql`
    mutation DeleteServicePointVente($id_base_adherent: String!, $id_actualite: Int!)  {
        delete_pointvente_actualite(where: {id_actualite: {_eq: $id_actualite}, id_base_adherent: {_eq: $id_base_adherent}}) {
            affected_rows
        }
        delete_actualite(where: {id_actualite: {_eq: $id_actualite}}) {
            affected_rows
        }
    }

`;
/**
 * Insert horaire exceptionnel repository.
 */
const DELETE_MODEL = gql`
    mutation DeleteModel($id_actualite: Int!)  {
        delete_pointvente_actualite(where: {id_actualite: {_eq: $id_actualite}}) {
            affected_rows
        }
        delete_actualite(where: {id_actualite: {_eq: $id_actualite}}) {
            affected_rows
        }
    }

`;

const ACTUALITE_ONLINE_Subscription = gql`
    subscription LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {date_debut: {_lte: "now()"}, _or: [{date_fin: {_is_null: true}}, {date_fin: {_gte: "now()"}}]}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;
const ACTUALITE_ONLINE_Query = gql`
    query LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {date_debut: {_lte: "now()"}, _or: [{date_fin: {_is_null: true}}, {date_fin: {_gte: "now()"}}]}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;

const ACTUALITE_CENTRALE_ONLINE_Subscription = gql`
    subscription LoadOnlineActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, date_debut: {_lte: "now()"}, _or: [{date_fin: {_is_null: true}}, {date_fin: {_gte: "now()"}}]}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;
const ACTUALITE_CENTRALE_ONLINE_Query = gql`
    query LoadOnlineActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, date_debut: {_lte: "now()"}, _or: [{date_fin: {_is_null: true}}, {date_fin: {_gte: "now()"}}]}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;

const ACTUALITE_PROGRAMMED_Subscription = gql`
    subscription LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {date_debut: {_gte: "now()"}}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;
const ACTUALITE_PROGRAMMED_Query = gql`
    query LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {date_debut: {_gte: "now()"}}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;

const ACTUALITE_CENTRALE_PROGRAMMED_Subscription = gql`
    subscription LoadProgrammedActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, date_debut: {_gte: "now()"}}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;
const ACTUALITE_CENTRALE_PROGRAMMED_Query = gql`
    query LoadProgrammedActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, date_debut: {_gte: "now()"}}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;

const ACTUALITE_PASSED_Subscription = gql`
    subscription LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {date_fin: {_lte: "now()"}}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;
const ACTUALITE_PASSED_Query = gql`
    query LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {date_fin: {_lte: "now()"}}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;

const ACTUALITE_CENTRALE_PASSED_Subscription = gql`
    subscription LoadPassedActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, date_fin: {_lte: "now()"}}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;
const ACTUALITE_CENTRALE_PASSED_Query = gql`
    query LoadPassedActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, date_fin: {_lte: "now()"}}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;

const ACTUALITE_DRAFT_Subscription = gql`
    subscription LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {draft: {_eq: true}}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;
const ACTUALITE_DRAFT_Query = gql`
    query LoadOnlineActualiteWithCodepanonceau($code_panonceau: String = null) {
        pointvente_actualite(where: {_or: {pointvente: {code_panonceau: {_eq: $code_panonceau}}},
        actualite: {draft: {_eq: true}}},
        order_by: [{id_actualite: asc}, {ordre: asc}],
        distinct_on: id_actualite) {
        id_actualite
        id_base_adherent
        ordre
        actualite {
          ${ACTUALITE_FIELDS}
        }
      }
    }
`;

const ACTUALITE_CENTRALE_DRAFT_Subscription = gql`
    subscription LoadDraftActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, draft: {_eq: true}}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;
const ACTUALITE_CENTRALE_DRAFT_Query = gql`
    query LoadDraftActualiteCentraleWithCodepanonceauCentrale($code_panonceau_centrale: String) {
  pointvente_actualite(where: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}},
  actualite: {actu_centrale: {_eq: true}, draft: {_eq: true}}},
  order_by: [{id_actualite: asc}, {ordre: asc}],
  distinct_on: id_actualite) {
    id_actualite
    id_base_adherent
    ordre
    actualite {
      actu_centrale
      date_debut
      date_fin
      draft
      id_actualite
      image
      lien
      model
      page_detail
      texte
      titre
      type
    }
  }
}
`;

/**
 * Actualite query repository.
 */
const MODELS_Subscription = gql`
    subscription LoadModels  {
        actualite(where: {model: {_eq: true}}, order_by: {titre: asc}) {
            ${ACTUALITE_FIELDS}
        }
    }
`;
const MODELS_Query = gql`
    query LoadModels {
        actualite(where: {model: {_eq: true}}, order_by: {titre: asc}) {
            ${ACTUALITE_FIELDS}
        }
    }
`;

const FindOneQuery = gql`
    query FindOne($id: Int!) {
        actualite(where: {id_actualite: {_eq: $id}}) {
            ${ACTUALITE_FIELDS}
            id_actualite
        }
    }

`;

/**
 * Actualite centrale query repository.
 */
const CENTRALE_ACTUALITE_Subscription = gql`
    subscription LoadCentraleActualite($code_panonceau_centrale: String!){
        actualite(where: {pointvente_actualites: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}}},
            actu_centrale: {_eq: true}}, order_by: {titre: asc}) {
            ${ACTUALITE_FIELDS}
        }
    }
`;
const CENTRALE_ACTUALITE_Query = gql`
    query LoadCentraleActualite($code_panonceau_centrale: String!){
        actualite(where: {pointvente_actualites: {pointvente: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}}},
            actu_centrale: {_eq: true}}, order_by: {titre: asc}) {
            ${ACTUALITE_FIELDS}
        }
    }
`;


/**
 * Actualite query repository.
 */
const ACTUALITE_PUBLISHED_Subscription = gql`
    subscription LoadPublishedActualite($id_base_adherent: String!)  {
        pointvente_actualite(where: {id_base_adherent: {_eq: $id_base_adherent},
            actualite: {draft: {_eq: false}, actu_centrale: {_eq: false}}},
            order_by: {ordre: asc}) {
            ordre
            id_base_adherent
            id_actualite
            actualite {
                ${ACTUALITE_FIELDS}
            }
        }
    }
`;
const ACTUALITE_PUBLISHED_Query = gql`
    query LoadPublishedActualite($id_base_adherent: String!)  {
        pointvente_actualite(where: {id_base_adherent: {_eq: $id_base_adherent},
            actualite: {draft: {_eq: false}, actu_centrale: {_eq: false}}},
            order_by: {ordre: asc}) {
            ordre
            id_base_adherent
            id_actualite
            actualite {
                ${ACTUALITE_FIELDS}
            }
        }
    }
`;
