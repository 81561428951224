import {effect, Inject, Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IUser, User} from '@models/user.model';
import {HttpClient} from '@angular/common/http';
import {KeycloakService} from 'keycloak-angular';
import {Router} from '@angular/router';
import {from} from 'rxjs';
import {LangService} from "@galec/services/global/lang.service";

@Injectable({
    providedIn: 'root'
})
/**
 * Service to manage user authentication's.
 */
export class UserAuthenticationService implements OnInit {

    public currentLang: string;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private http: HttpClient,
        @Inject(KeycloakService) private keycloakService: KeycloakService,
        private router: Router,
        private readonly langService: LangService
    ) {
        effect((): void => {
            this.currentLang = this.langService.getCurrentLang();
        });
        // Create user subject behavior from local storage data.
        this.currentUserSubject = new BehaviorSubject<User>(new User({...JSON.parse(localStorage.getItem('currentUser'))}));
        // Create observable from subject behavior.
        this.currentUser = this.currentUserSubject.asObservable();
    }

    ngOnInit() {
        this.currentLang = this.langService.getCurrentLang();
    }

    /**
     * Get Sso connected User.
     */
    public getSsoConnectedUser() {
        // Get parsed token from cloak service.
        const parsedToken: any = this.keycloakService.getKeycloakInstance().tokenParsed;
        if (parsedToken) {
            // Create user object.
            const user: User = new User({
                ...(this.currentUserValue ?? {}),
                token: this.keycloakService.getKeycloakInstance().token,
                roles: parsedToken.realm_access?.roles ?? [],
                username: parsedToken.preferred_username,
                lecPanonceau: parsedToken.lecPanonceau ?? '',
                isRedirected: this.currentUserValue.isRedirected,
                expired: parsedToken.exp
            });
            // Update observable with new data.
            this.currentUserSubject.next(user);
            // Save local storage data.
            this.saveUser();
        }
    }

    /**
     * Get current user value.
     * @return User   Connected User object value.
     */
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     * Getter user is redirect.
     * @return boolean    True if already redirected.
     */
    public get isRedirected(): boolean {
        const isRedirected = (Date.now() - this.currentUserValue?.isRedirected) / 1000;
        return (isRedirected <= 60 && isRedirected > 0) || this.currentUserValue?.expired >= Date.now() / 1000;
    }

    /**
     * Setter user is redirected.
     * @param isRedirected boolean    Set is redirected.
     */
    public set isRedirected(isRedirected) {
        this.currentUserSubject.next(new User({...this.currentUserValue, isRedirected: isRedirected ? Date.now() : 0}));
        this.saveUser();
    }

    /**
     * Setter user is redirected.
     */
    public editUserInfo(userInfo: any) {
        const user = {...this.currentUserValue, ...userInfo} as User;
        this.currentUserSubject.next(user);
        this.saveUser();
    }

    /**
     * Save user localstorage data.
     */
    private saveUser() {
        localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
    }

    /**
     * Logout user.
     */
    async logout(): Promise<any> {
        localStorage.removeItem('currentUser');
        return this.keycloakService?.logout()?.then((e): void => {
            this.currentUserSubject.next(new User(null));
        }).finally((): void => {
            // this.router.navigate(['/login'], {queryParams: {returnUrl: '/app/home'}});
            this.router.navigate([`/${this.currentLang}/login`], {queryParams: {returnUrl: `/${this.currentLang}/app/home`}});
        });
    }

    /**
     * Getter check if logged in.
     */
    public async isLoggedIn() {
        return this.keycloakService.isLoggedIn();
    }

    public getUserInfo() {
        const self = this;
        return from(new Promise((resolve, reject) => {
            const instance = self.keycloakService.getKeycloakInstance();
            if (typeof instance !== 'undefined') {
                self.keycloakService.getKeycloakInstance().loadUserInfo().then(e => resolve(e)).catch(err => resolve(null));
            } else {
                resolve(false);
            }
        }));
    }
}
