import {Component, effect, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, Routes} from '@angular/router';
import {AclService, UserAuthenticationService} from '@galec/services';
import * as _ from 'lodash';
import {routes} from '@galec/modules/back/back-routing.module';
import {PointVenteRepository} from "@galec/repositories";
import {User} from "@models/user.model";
import {CentraleRepository} from "@galec/repositories/centrale.gql";
import {LangService} from "@galec/services/global/lang.service";

@Component({
    selector: 'galec-back-container',
    templateUrl: './back-container.component.html',
    styleUrls: ['./back-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BackContainerComponent implements OnInit {
    @Input() showTitle = true;
    routes: Routes;
    init = true;
    burgerMenuOpen = false;
    delegation: any = null;
    showProfileBtn = false;
    user: User;
    role: any;
    isRouteAdmin = false;
    loading = true;
    name = '';

    public currentLang: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public userAuthenticationService: UserAuthenticationService,
        private aclService: AclService,
        private pointVenteRepository: PointVenteRepository,
        private centraleRepository: CentraleRepository,
        private langService: LangService,
    ) {
        effect((): void => {
            this.currentLang = this.langService.getCurrentLang();
            console.log('Récupération de la nouvelle langue :', this.currentLang);
        });
    }

    ngOnInit(): void {
        this.currentLang = this.langService.getCurrentLang();

        this.userAuthenticationService.currentUser.subscribe(user => {
            // Get new point vente.
            // Get sidebar routes.
            console.log(routes);
            this.routes = _.filter(routes, el => el?.data?.in_sidebar) as Routes;
            // Check role permission.
            this.routes = _.filter(this.routes, el => this.aclService.can(el.data.name)) as Routes;
            // Check if codePanonceau is required to access.
            this.routes = _.filter(this.routes, el => {
                return el.data.requirePointVente ? user.codePanonceau?.length > 0 : true;
            }) as Routes;
            // Sort sidebar routes by priorities.
            this.routes = _.orderBy(this.routes, ['data.priority'], 'desc') as Routes;
            this.user = user;
            this.getDelegation();
            this.router.events.subscribe(e => {
                if (e instanceof NavigationEnd) {
                    console.log('test');
                    this.getDelegation(e);
                }
            });
            console.log(user.role);
            console.log(user.roles);
            // Get centrale name.
            if (user.role === 'central') {
                this.centraleRepository.select({code_panonceau_centrale: this.user.codePanonceauCentral})
                    .subscribe(centrale => this.name = centrale.nom);
            }
        });
    }

    getDelegation(e: NavigationEnd = null) {
        // Check if route.
        this.isRouteAdmin = this.router.url.includes('admin');
        // Show back to profile button.
        console.log(e);
        this.showProfileBtn = ![`/${this.currentLang}/app/admin/national`, `/${this.currentLang}/app/admin/centrale`].includes(e?.urlAfterRedirects ?? this.router.url)
            || this.user.connectedAs !== this.user.role;
        // If route is not admin & is in delegation.
        this.role = this.getDelegatedRole(e?.urlAfterRedirects) ? 'national' : this.user.connectedAs;
        this.delegation = null;
        if (!this.isRouteAdmin && this.user.role !== 'magasin' && this.user.codePanonceau) {
            this.pointVenteRepository.getHyper().subscribe(pointVente => this.delegation = pointVente);
        } else if (this.user.role !== this.user.connectedAs && !this.router.url.includes('nationale')) {
            this.centraleRepository.select({code_panonceau_centrale: this.user.codePanonceauCentral})
                .subscribe(centrale => this.delegation = centrale);
        }
        this.loading = false;
    }

    private getDelegatedRole(url: string = this.router.url): boolean {
        // Show header back profile name.
        const urlWithoutParams = url.split('?')[0];
        return (url.includes('national') || urlWithoutParams === `/${this.currentLang}/app/admin/centrale`) && this.user.connectedAs !== this.user.role;
    }

    goBackAdmin() {
        this.getDelegatedRole();
        let toEdit: any = {codePanonceau: null};
        if (this.getDelegatedRole()) {
            toEdit = {...toEdit, codePanonceauCentral: null, connectedAs: this.user.role};
        }
        this.userAuthenticationService.editUserInfo(toEdit);
        console.log('ici');
        this.router.navigate([this.role === 'centrale' ? `/${this.currentLang}/app/admin/centrale` : `/${this.currentLang}/app/admin`]);
    }

    /**
     * Logout callback function.
     */
    logout() {
        this.userAuthenticationService?.logout().then();
    }

    public get User() {
        return this.userAuthenticationService.currentUserValue;
    }

    public get checkIsAdmin(): boolean {
        return this.User?.codePanonceau?.length > 0 && !this.router.url.includes('admin');
    }

    click(url: string) {
        this.burgerMenuOpen = false;
        // this.router.navigate([url]);
    }
}
