import {Component, effect, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControlOptions, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ImageComponent} from "@galec/modules/shared/components/image/image.component";
import {ImageService} from "@galec/modules/shared/services/image.service";
import {ActualiteRepository} from "@galec/repositories/actualite.gql";
import {Actualite} from "@models/actualite.model";
import * as moment from "moment";
import {PointVenteRepository} from "@galec/repositories";
import {PointVente} from "@models/pointVente.model";
import {DateUtil} from "@galec/helpers";
import {TYPES} from "@galec/modules/back/modules/actualites/models";
import {OwlOptions} from "ngx-owl-carousel-o";
import {AlertService, UserAuthenticationService} from "@galec/services";
import {CentraleRepository} from "@galec/repositories/centrale.gql";
import {RxwebValidators} from "@rxweb/reactive-form-validators";
import {MatDatepicker} from "@angular/material/datepicker";
import {LangService} from "@galec/services/global/lang.service";
import {Observable} from "rxjs";


@Component({
    selector: 'galec-actualite-add-new',
    templateUrl: './actualites.add-new.component.html',
    styleUrls: ['./actualites.add-new.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ActualitesAddNewComponent implements OnInit {
    @ViewChild('picker') datePicker: MatDatepicker<any>;
    MAX_ITEMS = 0;
    loading: boolean;
    models: Map<string, Actualite>;
    types = TYPES;
    step = 1;
    form: FormGroup;
    quillEditorRef;
    maxUploadFileSize = 1000000;
    format: Actualite;
    hyper: PointVente;
    finish = false;
    recap: any;
    selectTypeOrModel = false;
    typeSliderConfig: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        merge: false,
        dots: false,
        navSpeed: 700,
        navText: ['Previous', 'Next'],
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            740: {
                items: 3
            }
        }
    };
    modelSliderConfig: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        merge: true,
        margin: 30,
        dots: false,
        navSpeed: 700,
        navText: ['Previous', 'Next'],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 3
            },
            740: {
                items: 3
            },
            940: {
                items: 3
            }
        },
        nav: false
    };
    isModel = false;
    isCentral = false;
    isDisabled = false;
    edit: Actualite = new Actualite({});
    pointVentes: PointVente[] = [];
    actualites: Map<number, Actualite> = new Map<number, Actualite>();
    dateDisponible: Map<string, boolean> = new Map<string, boolean>();
    pointventeCheckboxes: any = {};
    imageName = '';
    oldUrl = null;
    express: PointVente[] = [];
    expressCheckboxes: any = {};
    express_selected = false;

    public currentLang: string;

    public TEXTE: {
        TITRE: Observable<string>,
        ETAPE_UN: Observable<string>,
        ETAPE_DEUX: Observable<string>,
        ETAPE_TROIS: Observable<string>,
        ETAPE_QUATRE: Observable<string>,
        CHOISIR_FORMAT: Observable<string>,
        NOUVEAU: Observable<string>,
        CREER_AZ: Observable<string>,
        MODELES: Observable<string>,
        CREER_EXISTANT: Observable<string>,
        SELECTIONNER_MODELE: Observable<string>,
        QUITTER: Observable<string>,
        CONFIRMATION: Observable<string>,
        CONFIRMER: Observable<string>,
        PRECEDENT: Observable<string>,
        SUIVANT: Observable<string>,
        DATE: {
            DEBUT: Observable<string>,
            FIN: Observable<string>,
        },
        HEURE: {
            DEBUT: Observable<string>,
            FIN: Observable<string>,
        },
        MESSAGE_MAX_ACTU: Observable<string>,
        CONCERNES_PAR_ACTU: Observable<string>,
        TOUS_LES_POINTS_VENTES: Observable<string>,
        CLIQUER_MODIFIER: Observable<string>,
        TITRE_ACTU: {
            MESSAGE: Observable<string>,
            OPTIONNEL: Observable<string>,
        },
        UPLOAD_IMAGE: {
            MESSAGE: Observable<string>,
            TAILLE: Observable<string>,
        },
        AJOUT_TEXTE: Observable<string>,
        DERNIERE_VERIF: Observable<string>,
        MODIF_VALIDER: Observable<string>,
        CONFIRM_SAISI: Observable<string>,
        DATE_VISIBLE: {
            PART_1: Observable<string>,
            PART_2: Observable<string>,
        },
        ALLER_ACCUEIL: Observable<string>,
        VOIR: Observable<string>,
        LIEN_IMAGE: Observable<string>,
        ACTUALITE_ENREGISTRER: {
            PART_1: Observable<string>,
            PART_2: Observable<string>,
        },
    };

    constructor(
        protected readonly formBuilder: FormBuilder,
        public route: ActivatedRoute,
        public router: Router,
        public dialog: MatDialog,
        public imageService: ImageService,
        public actualiteRepository: ActualiteRepository,
        public pointVenteRepository: PointVenteRepository,
        public alertService: AlertService,
        public activatedRoute: ActivatedRoute,
        public userAuthenticationService: UserAuthenticationService,
        public centraleRepository: CentraleRepository,
        protected readonly langService: LangService,
    ) {
        effect((): void => {
            this.currentLang = this.langService.getCurrentLang();
        });
    }

    ngOnInit(): void {
        this.currentLang = this.langService.getCurrentLang();
        this.loading = true;
        this.imageService.clear();
        // For Edit
        this.route.params.subscribe(params => {
            console.log(params);
            if (params?.id) {
                this.actualiteRepository.findOne(params.id).subscribe(actualite => {
                    console.log(actualite);
                    this.edit = actualite;
                    this.oldUrl = actualite.image;
                    this.format = actualite;
                    this.initForm(this.edit);
                    if (actualite.id_actualite) {
                        this.step = 2;
                    }
                });
            } else {
                this.setFormat(null);
            }
            this.getTextTrad();
        });
    }

    private getTextTrad(): void {
        this.TEXTE = {
            TITRE: this.langService.getTranslate((this.isModel) ? 'CATEGORIES.ACTUALITES.ETAPE.TITRE.MODEL' : 'CATEGORIES.ACTUALITES.ETAPE.TITRE.ACTUALITE'),
            ETAPE_UN: this.langService.getTranslate('CATEGORIES.ACTUALITES.ETAPE.UN'),
            ETAPE_DEUX: this.langService.getTranslate((this.isModel) ? 'CATEGORIES.ACTUALITES.ETAPE.DEUX.MODEL' : 'CATEGORIES.ACTUALITES.ETAPE.DEUX.ACTUALITE'),
            ETAPE_TROIS: this.langService.getTranslate('CATEGORIES.ACTUALITES.ETAPE.TROIS'),
            ETAPE_QUATRE: this.langService.getTranslate('CATEGORIES.ACTUALITES.ETAPE.QUATRE'),
            CHOISIR_FORMAT: this.langService.getTranslate('CATEGORIES.ACTUALITES.CHOISIR_FORMAT'),
            NOUVEAU: this.langService.getTranslate('UTILS.NOUVEAU'),
            CREER_AZ: this.langService.getTranslate('CATEGORIES.ACTUALITES.CREER_AZ'),
            MODELES: this.langService.getTranslate('CATEGORIES.ACTUALITES.EN_LIGNE.PLURIEL'),
            CREER_EXISTANT: this.langService.getTranslate('CATEGORIES.ACTUALITES.CREER_EXISTANT'),
            SELECTIONNER_MODELE: this.langService.getTranslate('CATEGORIES.ACTUALITES.SELECTIONNER_MODELE'),
            QUITTER: this.langService.getTranslate('UTILS.QUITTER'),
            CONFIRMATION: this.langService.getTranslate('UTILS.CONFIRMATION'),
            CONFIRMER: this.langService.getTranslate('UTILS.CONFIRMER'),
            PRECEDENT: this.langService.getTranslate('UTILS.PRECEDENT'),
            SUIVANT: this.langService.getTranslate('UTILS.SUIVANT'),
            DATE: {
                DEBUT: this.langService.getTranslate('CATEGORIES.ACTUALITES.DATE.DEBUT'),
                FIN: this.langService.getTranslate('CATEGORIES.ACTUALITES.DATE.FIN'),
            },
            HEURE: {
                DEBUT: this.langService.getTranslate('CATEGORIES.ACTUALITES.HEURE.DEBUT'),
                FIN: this.langService.getTranslate('CATEGORIES.ACTUALITES.HEURE.FIN'),
            },
            MESSAGE_MAX_ACTU: this.langService.getTranslate('CATEGORIES.ACTUALITES.MESSAGE_MAX_ACTU'),
            CONCERNES_PAR_ACTU: this.langService.getTranslate('CATEGORIES.ACTUALITES.CONCERNES_PAR_ACTU'),
            TOUS_LES_POINTS_VENTES: this.langService.getTranslate('CATEGORIES.ACTUALITES.TOUS_LES_POINTS_VENTES'),
            CLIQUER_MODIFIER: this.langService.getTranslate('CATEGORIES.ACTUALITES.CLIQUER_MODIFIER'),
            TITRE_ACTU: {
                MESSAGE: this.langService.getTranslate('CATEGORIES.ACTUALITES.TITRE_ACTU.MESSAGE'),
                OPTIONNEL: (this.format?.type === 'image') ? this.langService.getTranslate('CATEGORIES.ACTUALITES.TITRE_ACTU.OPTIONNEL') : new Observable<string>(),
            },
            UPLOAD_IMAGE: {
                MESSAGE: this.langService.getTranslate('CATEGORIES.ACTUALITES.UPLOAD_IMAGE.MESSAGE'),
                TAILLE: this.langService.getTranslate('CATEGORIES.ACTUALITES.UPLOAD_IMAGE.TAILLE'),
            },
            AJOUT_TEXTE: this.langService.getTranslate('CATEGORIES.ACTUALITES.AJOUT_TEXTE'),
            DERNIERE_VERIF: this.langService.getTranslate('CATEGORIES.ACTUALITES.DERNIERE_VERIF'),
            MODIF_VALIDER: this.langService.getTranslate('CATEGORIES.ACTUALITES.MODIF_VALIDER'),
            CONFIRM_SAISI: this.langService.getTranslate((this.isModel) ? 'CATEGORIES.ACTUALITES.CONFIRM_SAISI.MODELE' : 'CATEGORIES.ACTUALITES.CONFIRM_SAISI.ACTUALITE'),
            DATE_VISIBLE: {
                PART_1: this.langService.getTranslate('CATEGORIES.ACTUALITES.CLIQUER_MODIFIER.PART_1'),
                PART_2: this.langService.getTranslate('CATEGORIES.ACTUALITES.CLIQUER_MODIFIER.PART_2'),
            },
            ALLER_ACCUEIL: this.langService.getTranslate('CATEGORIES.ACTUALITES.ALLER_ACCUEIL'),
            VOIR: this.langService.getTranslate((this.isModel) ? 'CATEGORIES.ACTUALITES.VOIR.MODELE' : 'CATEGORIES.ACTUALITES.VOIR.ACTUALITE'),
            LIEN_IMAGE: this.langService.getTranslate('CATEGORIES.ACTUALITES.LIEN_IMAGE'),
            ACTUALITE_ENREGISTRER: {
                PART_1: this.langService.getTranslate('CATEGORIES.ACTUALITES.ACTUALITE_ENREGISTRER.PART_1'),
                PART_2: this.langService.getTranslate('CATEGORIES.ACTUALITES.ACTUALITE_ENREGISTRER.PART_2'),
            },
        };
    }

    /**
     * Set format or model actualite.
     * @param type  Format name
     * @param model     Model id.
     */
    setFormat(type: any, model: boolean = false) {
        if (!model) {
            this.format = new Actualite({type, typeActualite: type});
        } else {
            this.format = new Actualite({
                modelActualite: type.id_actualite,
                type: type.type,
                titre: type.titre,
                texte: type.texte,
                image: type.image
            });
        }
        this.initForm(this.format);
        return null;
    }

    /**
     * Initialize form
     * @param act   Actualite object.
     */
    initForm(act: Actualite) {
        // Build form
        this.form = this.formBuilder.group(
            {
                date_debut: [act.date_debut],
                heure_debut: [act.heure_debut],
                date_fin: [act.date_fin],
                heure_fin: [act.heure_fin],
                titre: [act.titre, Validators.required],
                texte: [act.texte],
                image: [act.image],
                imageBase64: [act.image],
                ordre: [act.ordre],
                lien: [act.lien, [RxwebValidators.url()]],
                Allconcepts: [false],
                pointventes: this.formBuilder.group(this.pointventeCheckboxes),
                Allconcept_e: [true],
                express: this.formBuilder.group(this.expressCheckboxes)
            }, {validators: this.customValidation()} as FormControlOptions
        );
        if (act === this.edit) {
            let selected = false;
            this.express.forEach(el => {
                if (this.form.get(`express.${el.id_base_adherent}`).value) {
                    selected = true;
                    this.express_selected = true;
                }
            });
            this.form.get('Allconcept_e').setValue(!selected);
        }
        if (this.edit?.id_actualite > 0 && !this.edit.draft) { this.isDisabled = true; }
        this.loading = false;
    }

    /**
     * Instance Wysiwyg.
     * @param editorInstance    Wysiwyg event.
     */
    getEditorInstance(editorInstance: any) {
        this.quillEditorRef = editorInstance;
    }

    // Sort key value by default.
    sort = () => 0;

    /**
     * Get Active class in tab.
     * @param step  Step number.
     */
    activeClass(step: number) {
        return step === this.step ? 'active' : (step < this.step ? 'done' : null);
    }

    /**
     * Go back url by type of page.
     */
    goBack() {
    }


    /**
     * Form step next event.
     */
    next() {
        if (this.step === 1 && !this.format.type) {
            this.selectTypeOrModel = true;
            return;
        }
        if (this.step === 2 && this.form.invalid) {
            return;
        }
        this.step++;
    }

    /**
     * Form step previous event.
     */
    previous() {
        this.step--;
    }

    /**
     * Get selected pointvente by type of page.
     */
    getPointventeActualite(): any {
        return null;
    }

    /**
     * Persist data from form value & page information.
     * @param draft  True if build a draft.
     */
    persisteData(draft = false): Actualite {
        const date_fin = DateUtil.getDateFromDatePicker(this.form.get('date_fin').value ?? moment(this.form.get('date_debut').value).add(1, 'years'));
        const ps = {
            ...this.format,
            ...this.edit,
            ...this.form.value,
            date_debut: DateUtil.getDateFromDatePicker(this.form.get('date_debut').value),
            date_fin,
            heure_fin: this.form.get('heure_fin').value ?? this.form.get('heure_debut').value ,
            pointvente_actualites: this.getPointventeActualite(),
            draft,
            model: this.isModel,
            actu_centrale: this.isCentral,
        };
        const actualite = new Actualite(ps);
        return actualite.persist();
    }

    /**
     * Build recap page.
     * @param actualite     Actualite object.
     */
    buildRecap(actualite: Actualite) {
        this.recap = {
            title: actualite.titre,
            date: moment(actualite.date_debut).isValid() ?
                moment(actualite.date_debut).locale('fr').format('DD MMMM') : null,
            time: moment(actualite.date_debut).format("HH:mm"),
            draft: actualite.draft
        };
        this.finish = true;
    }

    /**
     * Submit form event.
     */
    submit() {
        if (this.form.valid) {
            const actualite = this.persisteData();
            this.actualiteRepository.insert(actualite).subscribe(e => {
                this.buildRecap(actualite);
            }, err => this.alertService.error(err.toString()));
        }
    }

    showImage() {
        const dialogRef = this.dialog.open(ImageComponent, {
            data: {imageName: this.imageName, oldUrl: this.oldUrl},
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(e => {
            this.form.controls.imageBase64.setValue(e);
            this.imageService.currentImage.subscribe(data => {
                if (data?.url) {
                    this.form.controls.image.setValue(data.url);
                }
            });
        });
    }

    saveImage() {
        this.imageService.currentImage.subscribe(data => {
            this.imageService.saveImage(this.imageName);
        });
    }

    formValidation(formGroup: FormGroup) {
        if (this.format) {
            // Image is required if model/type selected require it.
            formGroup.controls.image.setErrors(
                !formGroup.value.image && this.format.type !== 'text' ? {required: true} : null);
            // Text is required if model/type selected require it.
            formGroup.controls.texte.setErrors(
                !formGroup.value.texte && this.format.type !== 'image' ? {required: true} : null);
            // Date fin required if heure fin is not empty.
            formGroup.controls.date_fin.setErrors(
                !formGroup.value.date_fin && formGroup.value.heure_fin ? {required: true} : null);
            // Heure fin is required if date fin is selected.
            formGroup.controls.heure_fin.setErrors(
                !formGroup.value.heure_fin && formGroup.value.date_fin ? {required: true} : null);
        }
    }

    customValidation() {
        return (formGroup: FormGroup) => this.formValidation(formGroup);
    }

    checkAll($event: any) {
    }

    conceptChecked($event: any) {
    }

    filterMaxActualiteByPeriod(d: Date | null): boolean {
        return false;
    }

    clearDate(fieldName: string) {
        this.form.controls[fieldName].setValue(null);
    }

    expressChecked($event: any, express: PointVente) {
    }
}
