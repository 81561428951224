import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
    ControlErrorContainerDirective,
    ControlErrorsDirective,
    FormSubmitDirective, HasRoleDirective, ImageFallbackDirective
} from '@galec/modules/shared/directives';
import {
    ActualitesAddNewComponent,
    ActualiteThumbnailComponent,
    ControlErrorComponent,
    DialogComponent,
    EditButtonComponent,
    ListConceptsComponent,
    ExportDataComponent,
} from '@galec/modules/shared/components';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MapValuesPipe, ObjectKeysPipe, SafeHtmlPipe} from './pipes';
import {IconComponent} from '@galec/modules/shared/components/icon/icon.component';
import {TimePipe} from './pipes/time.pipe';
import {ImageComponent} from './components/image/image.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ImageService} from "@galec/modules/shared/services/image.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { PluralComponent } from './components/plural/plural.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CarouselModule} from "ngx-owl-carousel-o";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {QuillModule} from "ngx-quill";
import {MatTooltipModule} from "@angular/material/tooltip";
import {RouterModule} from "@angular/router";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ActualitesRoutingModule} from "@galec/modules/back/modules/actualites/actualites-routing.module";
import {MatInputModule} from "@angular/material/input";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import { SafePipe } from './pipes/safe.pipe';
import {TranslatePipe} from "@ngx-translate/core";
import {ChangeLangComponent} from "@components/change-lang/change-lang.component";


@NgModule({
    declarations: [
        ActualitesAddNewComponent,
        ControlErrorsDirective,
        FormSubmitDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,
        ObjectKeysPipe,
        MapValuesPipe,
        HasRoleDirective,
        IconComponent,
        TimePipe,
        ImageComponent,
        PluralComponent,
        SafeHtmlPipe,
        EditButtonComponent,
        ListConceptsComponent,
        DialogComponent,
        ImageFallbackDirective,
        ActualiteThumbnailComponent,
        SafePipe,
        ExportDataComponent,
        ChangeLangComponent,
    ],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        CommonModule,
        ImageCropperModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        QuillModule.forRoot(),
        CKEditorModule,
        DragDropModule,
        ImageCropperModule,
        NgxMaterialTimepickerModule,
        MatProgressSpinnerModule,
        CarouselModule,
        MatTooltipModule,
        RouterModule,
        RxReactiveFormsModule,
        TranslatePipe,
        NgOptimizedImage
    ],
    exports: [
        ControlErrorsDirective,
        FormSubmitDirective,
        ControlErrorComponent,
        ControlErrorContainerDirective,
        ObjectKeysPipe,
        MapValuesPipe,
        HasRoleDirective,
        IconComponent,
        PluralComponent,
        SafeHtmlPipe,
        EditButtonComponent,
        ListConceptsComponent,
        DialogComponent,
        ImageFallbackDirective,
        ActualiteThumbnailComponent,
        SafePipe,
        ChangeLangComponent
    ],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        ImageService
    ]
})
export class SharedModule {
}
