import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {UserAuthenticationService} from '@galec/services';
import {LangService} from "@galec/services/global/lang.service";

@Component({
    selector: 'galec-notpermitted',
    templateUrl: './notpermitted.component.html',
    styleUrls: ['./notpermitted.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotpermittedComponent {

    constructor(
        private readonly router: Router,
        private readonly userAuthenticationService: UserAuthenticationService,
        protected readonly langService: LangService,
    ) {
    }

    /**
     * Go back in route history.
     */
    goBack() {
        this.langService.navigate('/app/home');
    }

    logout() {
        this.userAuthenticationService?.logout().then();
    }
}
