import {Apollo, gql} from 'apollo-angular';
import {DocumentNode, FetchResult} from '@apollo/client/core';

import {Injectable} from '@angular/core';

import {PointVente} from '@models/pointVente.model';
import * as moment from 'moment';
import {BaseRepository} from '@galec/repositories/baseRepository';
import {combineLatest, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {ALL_HORAIRE_FIELD} from "@galec/repositories/horaire.gql";
import {ALL_HORAIRE_EXCEPTIONNELLE_FIELD} from "@galec/repositories/ouverturesFermeturesExceptionnelles.gql";
import {UserAuthenticationService} from "@galec/services/user/user-authentication.service";
import {Activitecommerciale} from "@models/activitecommerciale.model";
import {User} from "@models/user.model";
import {Centrale} from "@galec/database/models/backend";


@Injectable()
/**
 * Horaire repository service.
 */
export class PointVenteRepository extends BaseRepository {

    protected DELETE_QUERY: DocumentNode;
    protected INSERT_QUERY: DocumentNode;
    protected LIST_QUERY: DocumentNode = SelectPointVenteEtHorairesGql;
    protected UPDATE_QUERY: DocumentNode = UpdatePointVenteGql;

    constructor(protected apollo: Apollo, protected userAuthenticationService: UserAuthenticationService) {
        super();
        this.init();
    }

    /**
     * @inheritDoc
     */
    public select(variables?: any): Observable<Map<string, PointVente>> {
        // And current date to query.
        variables.date = moment().format('YYYY-MM-DD');
        return super.select(variables).pipe(map(this.formatPointVente));
    }

    public loadPointVenteWithConcepts() {
        return this.queryHandler(LoadAllPointVente, {
            codePanonceau: this.connectedUser.codePanonceau,
            date: moment().format('YYYY-MM-DD')
        })
            .pipe(map(this.formatPointVente));
    }

    public loadPointVente(): Observable<any> {
        return combineLatest([
            this.getHyper(),
            this.getConcepts()
        ]);
    }

    public getHyper() {
        return this.watchAndSubscribe(GetHyper_Query, GetHyper_Subscription, {
            codePanonceau: this.connectedUser.codePanonceau,
            date: moment().format('YYYY-MM-DD')
        })
            .pipe(map(this.formatPointVente))
            .pipe(map((res: any) => {
                return res.size > 0 ? [...res.values()][0] : null;
            }));
    }

    public getConcepts() {
        return this.watchAndSubscribe(GetConcepts_Query, GetConcepts_Subscription, {
            codePanonceau: this.connectedUser.codePanonceau,
            date: moment().format('YYYY-MM-DD')
        })
            .pipe(map((result: any) => {
                const acitiviteCommerciales: Map<string, Activitecommerciale> = new Map<string, Activitecommerciale>();
                result.data?.activitecommerciale?.forEach((value, key) => {
                    const acitiviteCommerciale = new Activitecommerciale(value);
                    if (acitiviteCommerciale.pointventes.size > 0) {
                        acitiviteCommerciales.set(acitiviteCommerciale.id, acitiviteCommerciale);
                    }
                });
                return acitiviteCommerciales;
            }));
    }

    /**
     * Select point vente by key.
     * @param key string    Key to filter with.
     * @param user User  User Object.
     * @return Observable<any>      Observable with returned value.
     */
    public selectByKey(key: string, user?: User, isWithPdvInactive?: boolean): Observable<any> {
        const variables: any = {key: `%${key}%`};
        // Set weired name to central if not admin.
        variables.keyCentral = user.connectedAs === 'national' ? variables.key : 'NULL';
        // Set panonceau if is central.
        if (user.codePanonceauCentral && user.connectedAs === 'central') {
            variables.codePanonceauCentrale = user.codePanonceauCentral;
        }
        return this.queryHandler(isWithPdvInactive ? (user.codePanonceauCentral ?
                SelectPointVenteAutocompleteWithInactive : SelectPointVenteAutocompleteWithInactiveNational) :
            (user.codePanonceauCentral ? SelectPointVenteAutocomplete : SelectPointVenteAutocompleteNational), variables)
            .pipe(map((result: any) => {
                const list = [];
                result.data?.pointvente?.forEach((value) => {
                    list.push({
                        code_panonceau: value.code_panonceau,
                        id: value.id_base_adherent,
                        id_base_adherent_acdelec: value.id_base_adherent_acdelec,
                        cp: value.cp,
                        libelle: value.libelle,
                        ville: value.ville,
                        type: 'pointVente',
                        adresse: value.adresse,
                        est_actif: value.est_actif
                    });
                });
                result.data?.centrale?.forEach((value: Centrale) => {
                    list.push({
                        code_panonceau: '',
                        id: value.code_panonceau_centrale,
                        cp: '',
                        libelle: value.nom,
                        ville: '',
                        type: 'central',
                    });
                });
                return list;
            }));
    }

    public selectActiviteCommerciale(key?: any): Observable<any> {
        return this.queryHandler(selectActiviteCommerciale, key);
    }

    public selectPointDeVenteByiD(id_base_adherent: any): Observable<any> {
        return this.queryHandler(selectPointDeVenteByiD, {id_base_adherent});
    }

    public selectNumSiren(num_siren: any): Observable<any> {
        return this.queryHandler(numSirenInfo, {num_siren});
    }

    public selectNumOrdre(codePanonceau_codeActivite: any): Observable<any> {
        const id_base_adherent: any = {codePanonceau_codeActivite: `%${codePanonceau_codeActivite}%`};
        return this.queryHandler(selectNumeroOrdre, {id_base_adherent: id_base_adherent.codePanonceau_codeActivite});
    }

    /**
     * Update Visuel Point vente.
     * @param value any   Base object.
     * @return Observable<FetchResult<{}>>  Observable with result.
     */
    public updateVisuelPointVente(value: any): Observable<FetchResult<{}>> {
        return this.mutationHandler(UpdatePointVenteVisuel, value);
    }

    public updateDonneAdministratives(numero_siren: any): Observable<FetchResult<{}>> {
        return this.mutationHandler(updateDonnesAdministratives, numero_siren);
    }

    public savePointDeVente(pointvente: any): Observable<FetchResult<{}>> {

        return super.mutationHandler(insertPointDevente, {
            adresse: pointvente.adresse,
            code_activite: pointvente.code_activite,
            code_panonceau: pointvente.code_panonceau,
            id_base_adherent_acdelec: pointvente.id_base_adherent_acdelec,
            cp: pointvente.cp,
            telephone: pointvente.telephone,
            url_magasin: pointvente.url_magasin,
            ville: pointvente.ville,
            email: pointvente.email,
            est_actif: pointvente.est_actif,
            est_hyper: pointvente.est_hyper,
            gps_long: pointvente.gps_long,
            gps_lat: pointvente.gps_lat,
            est_concept: pointvente.est_concept,
            est_super: pointvente.est_super,
            // numero_siren: pointvente.donnee_administrative.numero_siren,
            nom: pointvente.nom,
            libelle: pointvente.libelle,
            id_gouv: pointvente.id_gouv,
            id_base_adherent: pointvente.id_base_adherent,
            numero_ordre: pointvente.numero_ordre,
            donnee_administrative: pointvente.donnee_administrative,
            horaires: pointvente.horaires
        });
    }

    // updatePointDeventeDonneAdministrativeHoraires
    public updatePointDeventeDonneAdministrativeHoraires(pointvente: any): Observable<FetchResult<{}>> {

        return super.mutationHandler(updatePointDeventeDonneAdministrativeHoraires, {
            adresse: pointvente.adresse,
            code_activite: pointvente.code_activite,
            code_panonceau: pointvente.code_panonceau,
            id_base_adherent_acdelec: pointvente.id_base_adherent_acdelec,
            telephone: pointvente.telephone,
            url_magasin: pointvente.url_magasin,
            ville: pointvente.ville,
            cp: pointvente.cp,
            email: pointvente.email,
            est_actif: pointvente.est_actif,
            est_hyper: pointvente.est_hyper,
            gps_long: pointvente.gps_long,
            gps_lat: pointvente.gps_lat,
            est_concept: pointvente.est_concept,
            est_super: pointvente.est_super,
            // numero_siren: pointvente.donnee_administrative.numero_siren,
            nom: pointvente.nom,
            libelle: pointvente.libelle,
            id_gouv: pointvente.id_gouv,
            id_base_adherent: pointvente.id_base_adherent,
            numero_ordre: pointvente.numero_ordre,
            donnee_administrative: pointvente.donnee_administrative,
            horaires: pointvente.horaires
        });
    }

        public getAllPointVenteNationalIsActif(): Observable<any> {
        return this.queryHandler(GET_ALL_POINT_VENTE_NATIONAL_IS_ACTIF, {isActive: true});
    }

    public getAllPointVenteCentraleIsActif(codePanonceauCentrale: string): Observable<any> {
        return this.queryHandler(GET_ALL_POINT_VENTE_CENTRALE_IS_ACTIF, {codePanonceauCentrale, isActive: true});
    }

    private formatPointVente(result: any): Map<string, PointVente> {
        const pointVentes: Map<string, PointVente> = new Map<string, PointVente>();
        result.data?.pointvente?.forEach((value: PointVente, key) => {
            const pointVente = new PointVente(value);
            pointVentes.set(pointVente.id, pointVente);
        });
        return pointVentes;
    }


}

export const ALL_POINTVENTE_FIELD = `
    id_base_adherent
    adresse
    code_activite
    code_panonceau
    id_base_adherent_acdelec
    cp
    est_actif
    est_concept
    est_hyper
    est_super
    gps_lat
    gps_long
    nom
    libelle
    numero_ordre
    telephone
    url_magasin
    ville
    email
    visuel_desktop
    visuel_mobile

    zonechalandise {
      url_facebook
      url_galerie
      centrale {
        nom
      }
    }
    activitecommerciale {
      libelle
      code_activite
    }
`;
/**
 * Point vente horaire query repository.
 */
const SelectPointVenteEtHorairesGql = gql`
    query pointVenteetHoraires($codePanonceau: String!, $date: date) {
        pointvente(where: {id_base_adherent: {_eq: $codePanonceau}, est_actif: {_eq: true}}) {
            ${ALL_POINTVENTE_FIELD}
            horaires {
                ${ALL_HORAIRE_FIELD}
            }
            ouvertures_fermetures_exceptionnelless(where: {_or: [{date_fin: {_gte: $date}}, {date: {_gte: $date}}]},
                order_by: {date: asc}) {
                ${ALL_HORAIRE_EXCEPTIONNELLE_FIELD}
            }
        }
    }
`;
const GET_HYPER = `
pointvente(where: {code_panonceau: {_eq: $codePanonceau}, _or: [{est_hyper: {_eq: true}}, {est_super: {_eq: true}}],
    est_actif: {_eq: true}}, limit: 1) {
    ${ALL_POINTVENTE_FIELD}
    horaires {
        ${ALL_HORAIRE_FIELD}
    }
    ouvertures_fermetures_exceptionnelless(where: {_or: [{date_fin: {_gte: $date}}, {date: {_gte: $date}}]},
        order_by: {date: asc}) {
        ${ALL_HORAIRE_EXCEPTIONNELLE_FIELD}
    }
}`;
const GET_CONCEPTS = `
activitecommerciale(where: {pointventes: {code_panonceau: {_eq: $codePanonceau}}},
    order_by: {libelle: asc}) {
    code_activite
    libelle
    pointventes(where: {est_hyper: {_eq: false}, est_super: {_eq: false}, code_panonceau: {_eq: $codePanonceau},
        est_actif: {_eq: true}}, order_by: {numero_ordre: asc})  {
        ${ALL_POINTVENTE_FIELD}
        horaires {
            ${ALL_HORAIRE_FIELD}
        }
        ouvertures_fermetures_exceptionnelless(where: {_or: [{date_fin: {_gte: $date}}, {date: {_gte: $date}}]},
            order_by: {date: asc}) {
            ${ALL_HORAIRE_EXCEPTIONNELLE_FIELD}
        }
    }
}`;
/**
 * Point vente horaire query repository.
 */
const GetHyper_Subscription = gql`
    subscription getHyper($codePanonceau: String!, $date: date) {
        ${GET_HYPER}
    }
`;
const GetHyper_Query = gql`
    query getHyper($codePanonceau: String!, $date: date) {
        ${GET_HYPER}
    }
`;

const GetConcepts_Subscription = gql`
    subscription getConcepts($codePanonceau: String!, $date: date) {
        ${GET_CONCEPTS}
    }
`;

const GetConcepts_Query = gql`
    query getConcepts($codePanonceau: String!, $date: date) {
        ${GET_CONCEPTS}
    }
`;

const SelectPointVenteAutocomplete = gql`
    query SelectPointVenteAutocomplete($codePanonceauCentrale: String, $key: String!, $keyCentral: String!) {
        pointvente(where: {est_actif: {_eq: true}, zonechalandise: {code_panonceau_centrale: {_eq: $codePanonceauCentrale}},
            _or: [{nom: {_ilike: $key}}, {id_base_adherent: {_ilike: $key}}, {libelle: {_ilike: $key}},
                {cp: {_ilike: $key}}, {code_panonceau: {_ilike: $key}}, {id_base_adherent_acdelec: {_ilike: $key}},
                {ville: {_ilike: $key}}], est_concept: {_eq: false}}) {
            id_base_adherent
            code_activite
            code_panonceau
            id_base_adherent_acdelec
            cp
            nom
            libelle
            ville
            est_actif
            adresse
        }
        centrale(where: {_or: [{code_panonceau_centrale: {_ilike: $keyCentral}}, {nom: {_ilike: $keyCentral}}]}) {
            nom
            code_panonceau_centrale
        }
    }
`;

const SelectPointVenteAutocompleteNational = gql`
    query SelectPointVenteAutocomplete($key: String!, $keyCentral: String!) {
  pointvente(where: {est_actif: {_eq: true}, zonechalandise: {code_panonceau_centrale: {}}, _or: [{nom: {_ilike: $key}}, {id_base_adherent: {_ilike: $key}}, {libelle: {_ilike: $key}}, {cp: {_ilike: $key}}, {code_panonceau: {_ilike: $key}}, {id_base_adherent_acdelec: {_ilike: $key}}, {ville: {_ilike: $key}}], est_concept: {_eq: false}}) {
    id_base_adherent
    code_activite
    code_panonceau
    id_base_adherent_acdelec
    cp
    nom
    libelle
    ville
    est_actif
    adresse
  }
  centrale(where: {_or: [{code_panonceau_centrale: {_ilike: $keyCentral}}, {nom: {_ilike: $keyCentral}}]}) {
    nom
    code_panonceau_centrale
  }
}
`;

const SelectPointVenteAutocompleteWithInactive = gql`
    query SelectPointVenteAutocompleteWithInactive($codePanonceauCentrale: String, $key: String!, $keyCentral: String!) {
        pointvente(where: { zonechalandise: {code_panonceau_centrale: {_eq: $codePanonceauCentrale}},
            _or: [{nom: {_ilike: $key}}, {id_base_adherent: {_ilike: $key}}, {libelle: {_ilike: $key}},
                {cp: {_ilike: $key}}, {code_panonceau: {_ilike: $key}},{id_base_adherent_acdelec: {_ilike: $key}},
                {ville: {_ilike: $key}}]}) {
            id_base_adherent
            code_activite
            code_panonceau
            id_base_adherent_acdelec
            cp
            nom
            libelle
            ville
            est_actif
            adresse
        }
        centrale(where: {_or: [{code_panonceau_centrale: {_ilike: $keyCentral}}, {nom: {_ilike: $keyCentral}}]}) {
            nom
            code_panonceau_centrale
        }
    }
`;

const SelectPointVenteAutocompleteWithInactiveNational = gql`
    query SelectPointVenteAutocompleteWithInactive($key: String!, $keyCentral: String!) {
  pointvente(where: {zonechalandise: {code_panonceau_centrale: {}}, _or: [{nom: {_ilike: $key}}, {id_base_adherent: {_ilike: $key}}, {libelle: {_ilike: $key}}, {cp: {_ilike: $key}}, {code_panonceau: {_ilike: $key}}, {id_base_adherent_acdelec: {_ilike: $key}}, {ville: {_ilike: $key}}]}) {
    id_base_adherent
    code_activite
    code_panonceau
    id_base_adherent_acdelec
    cp
    nom
    libelle
    ville
    est_actif
    adresse
  }
  centrale(where: {_or: [{code_panonceau_centrale: {_ilike: $keyCentral}}, {nom: {_ilike: $keyCentral}}]}) {
    nom
    code_panonceau_centrale
  }
}
`;

const selectActiviteCommerciale =
    gql`
    query selectActiviteCommerciale {
        activitecommerciale (order_by: {libelle: asc}){
            code_activite
            libelle
        }
    }
`;
// id_base_adherent_acdelec
const UpdatePointVenteGql = gql`
    mutation UpdatePointVente($id_base_adherent: String!, $adresse: String!, $libelle: String, $ville: String!,
        $cp: String!, $gps_lat: String!, $gps_long: String!, $telephone: String!, $email: String)  {
        update_pointvente(where: {id_base_adherent: {_eq: $id_base_adherent}}, _set:
        {adresse: $adresse, libelle: $libelle, ville: $ville, cp: $cp, gps_lat: $gps_lat, gps_long: $gps_long,
            telephone: $telephone, email: $email}) {
            affected_rows
        }
    }`;

const UpdatePointVenteVisuel = gql`
    mutation UpdatePointVente($id_base_adherent: String!, $visuel_desktop: String, $visuel_mobile: String)  {
        update_pointvente(where: {id_base_adherent: {_eq: $id_base_adherent}}, _set:
        {visuel_desktop: $visuel_desktop, visuel_mobile: $visuel_mobile}) {
            affected_rows
        }
    }`;
const numSirenInfo = gql`query selectDonneAdministratives($num_siren: String) {
    donnees_administratives(where: {numero_siren: {_eq: $num_siren}}) {
        adresse_siege_social
        capital_social
        code_postal_siege_social
        forme_sociale
        nom_adherent
        numero_rcs
        numero_siren
        numero_siret
        numero_tva_intra_commun
        prenom_adherent
        raison_sociale
        ville_immatriculation
        ville_siege_social
        autorisations_etablissement
    }
}`;
/**
 * Point vente horaire query repository.
 */
const LoadAllPointVente = gql`
    query getAll($codePanonceau: String!, $date: date) {
        pointvente(where: {code_panonceau: {_eq: $codePanonceau}, est_actif: {_eq: true}},
            order_by: {est_hyper: desc, est_super: desc, libelle: asc}) {
            ${ALL_POINTVENTE_FIELD}
            horaires {
                ${ALL_HORAIRE_FIELD}
            }
            ouvertures_fermetures_exceptionnelless(where: {_or: [{date_fin: {_gte: $date}}, {date: {_gte: $date}}]},
                order_by: {date: asc}) {
                ${ALL_HORAIRE_EXCEPTIONNELLE_FIELD}
            }
        }
    }
`;
// url_magasin  ville  gps_lat
const insertPointDevente = gql`mutation insertPointDevente($adresse: String , $code_activite: String ,
    $code_panonceau: String, $id_base_adherent_acdelec: String,  $cp: String , $ville: String,  $telephone: String,  $url_magasin: String,   $email: String , $est_actif: Boolean , $est_hyper: Boolean ,
    $gps_long: String, $gps_lat: String, $est_concept: Boolean , $est_super: Boolean , $numero_siren: String  ,
    $nom: String, $libelle: String, $id_gouv: String, $id_base_adherent: String,
    $numero_ordre: numeric  , $donnee_administrative: donnees_administratives_insert_input! , $horaires: [horaire_insert_input!]! ) {
    insert_pointvente(objects: {adresse: $adresse, code_activite: $code_activite,
        code_panonceau: $code_panonceau, id_base_adherent_acdelec: $id_base_adherent_acdelec, cp: $cp, ville: $ville, telephone: $telephone,  url_magasin: $url_magasin,  email: $email,
        est_actif: $est_actif, est_hyper: $est_hyper, est_super: $est_super, est_concept: $est_concept,
        gps_lat: $gps_lat, gps_long: $gps_long, id_base_adherent: $id_base_adherent, id_gouv: $id_gouv,
        libelle: $libelle, nom: $nom, numero_ordre: $numero_ordre,
        donnee_administrative: {on_conflict: {constraint: donnee_administratives_pkey, update_columns:
            [
            numero_siret,
            adresse_siege_social,
            capital_social,
            code_postal_siege_social,
            forme_sociale,
            nom_adherent,
            numero_rcs,
            numero_siret,
            numero_tva_intra_commun,
            prenom_adherent,
            raison_sociale,
            ville_immatriculation,
            ville_siege_social]}, data: $donnee_administrative},
        horaires: {data: $horaires}
    }) {
        affected_rows
    }
}`;
// id_base_adherent_acdelec
// update pointDeVente
const updatePointDeventeDonneAdministrativeHoraires = gql`mutation updatePointDevente($adresse: String , $code_activite: String ,
    $code_panonceau: String , $id_base_adherent_acdelec: String , $cp: String , $ville: String,  $telephone: String,  $url_magasin: String, $email: String , $est_actif: Boolean , $est_hyper: Boolean ,
    $gps_long: String  , $gps_lat: String  , $est_concept: Boolean , $est_super: Boolean , $numero_siren: String  ,
    $nom: String  , $libelle: String  , $id_gouv: String  , $id_base_adherent: String ,
    $numero_ordre: numeric  , $donnee_administrative: donnees_administratives_insert_input! , $horaires: [horaire_insert_input!]! ) {
    insert_pointvente(objects: {adresse: $adresse, code_activite: $code_activite,
        code_panonceau: $code_panonceau, id_base_adherent_acdelec: $id_base_adherent_acdelec, cp: $cp, ville: $ville, telephone: $telephone,  url_magasin: $url_magasin, email: $email,
        est_actif: $est_actif, est_hyper: $est_hyper, est_super: $est_super, est_concept: $est_concept,
        gps_lat: $gps_lat, gps_long: $gps_long, id_base_adherent: $id_base_adherent, id_gouv: $id_gouv,
        libelle: $libelle, nom: $nom, numero_ordre: $numero_ordre,
        donnee_administrative: {on_conflict: {constraint: donnee_administratives_pkey, update_columns:
            [
            numero_siren,
            numero_siret,
            adresse_siege_social,
            capital_social,
            code_postal_siege_social
            forme_sociale,
            nom_adherent,
            numero_rcs,
            numero_siret,
            numero_tva_intra_commun,
            prenom_adherent,
            raison_sociale,
            ville_immatriculation,
            ville_siege_social]}, data: $donnee_administrative},
        horaires: {data: $horaires,on_conflict: {constraint: horaire_pkey, update_columns:
            [
            horaire_fermeture_apm,
            horaire_fermeture_matin,
            horaire_ouverture_apm,
            horaire_ouverture_matin,
            nom_jour
        ]}}
    },on_conflict: {constraint: pointvente_pkey, update_columns: [
        adresse,
        code_activite,
        code_panonceau,
        id_base_adherent_acdelec,
        cp,
        ville,
        telephone,
        url_magasin,
        email,
        est_actif,
        est_hyper,
        est_super,
        est_concept,
        gps_lat,
        gps_long,
        id_base_adherent,
        id_gouv,
        libelle,
        nom,
        numero_ordre,
        numero_siren,
    ]}) {
        affected_rows
    }
}
`;

const selectPointDeVenteByiD = gql`query selectPointDeVenteByiD($id_base_adherent: String ) {
    pointvente(where: {id_base_adherent: {_eq: $id_base_adherent}}) {
        code_activite
        adresse
        code_panonceau
        cp
        est_actif
        est_concept
        est_hyper
        est_super
        gps_lat
        gps_long
        id_base_adherent
        id_gouv
        libelle
        nom
        numero_ordre
        numero_siren
        telephone
        ville
        url_site
        url_magasin
        horaires {
            code_jour
            horaire_fermeture_apm
            horaire_fermeture_matin
            horaire_ouverture_apm
            horaire_ouverture_matin
            nom_jour
          }
          donnee_administrative {
            adresse_siege_social
            capital_social
            code_postal_siege_social
            forme_sociale
            nom_adherent
            numero_rcs
            numero_siren
            numero_siret
            numero_tva_intra_commun
            prenom_adherent
            raison_sociale
            ville_immatriculation
            ville_siege_social
            autorisations_etablissement
          }
    }
}
`;

const selectNumeroOrdre = gql`query selectNumeroOrdre($id_base_adherent: String! ) {
    pointvente(where: {id_base_adherent: {_like: $id_base_adherent}}) {
      numero_ordre
    }
  }`;

const updateDonnesAdministratives = gql`mutation updateDonnesAdministratives($numero_siren: String, $adresse_siege_social: String , $code_postal_siege_social: String , $forme_sociale: String, $nom_adherent: String = "", $numero_rcs: String, $numero_siren: String , $numero_siret: String, $numero_tva_intra_commun: String , $prenom_adherent: String , $raison_sociale: String , $ville_immatriculation: String , $ville_siege_social: String , $capital_social: String) {
    update_donnees_administratives(where: {numero_siren: {_eq: $numero_siren}},
      _set: {adresse_siege_social: $adresse_siege_social,
      capital_social: $capital_social,
      code_postal_siege_social: $code_postal_siege_social,
      forme_sociale: $forme_sociale,
      nom_adherent: $nom_adherent,
      numero_rcs: $numero_rcs,
      numero_siren: $numero_siren,
      numero_siret: $numero_siret,
      numero_tva_intra_commun: $numero_tva_intra_commun,
      prenom_adherent: $prenom_adherent,
      raison_sociale: $raison_sociale,
      ville_immatriculation: $ville_immatriculation,
      ville_siege_social: $ville_siege_social}) {
      affected_rows
    }
  }
  `;

const GET_ALL_POINT_VENTE_NATIONAL_IS_ACTIF = gql`query ($isActive: Boolean) {
    pointvente(where: {est_actif: {_eq: $isActive}}) {
        ${ALL_POINTVENTE_FIELD}
        horaires {
            ${ALL_HORAIRE_FIELD}
        }
    }
}`;

const GET_ALL_POINT_VENTE_CENTRALE_IS_ACTIF = gql`query ($codePanonceauCentrale: String, $isActive: Boolean) {
    pointvente(where: {zonechalandise: {centrale: {code_panonceau_centrale: {_eq: $codePanonceauCentrale}}}, est_actif: {_eq: $isActive}}) {
        ${ALL_POINTVENTE_FIELD}
        horaires {
            ${ALL_HORAIRE_FIELD}
        }
    }
}`;
