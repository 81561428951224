import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {UserAuthenticationService} from "@galec/services";
import {LangService} from "@galec/services/global/lang.service";

@Component({
    selector: 'galec-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent {

    constructor(
        private readonly router: Router,
        private readonly userAuthenticationService: UserAuthenticationService,
        protected readonly langService: LangService,
    ) {
    }

    /**
     * Go back in route history.
     */
    goBack() {
        this.langService.navigate('/app/home');
    }

    logout() {
        this.userAuthenticationService.logout().then();
    }

}
