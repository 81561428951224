<button (click)="onNoClick()" class="popin-close"><i class="icon-fermer icon-fermer-popup"></i></button>
<div class="c-card__title popup-title-attention">{{ data.title }}</div>
<div class="mb-5 d-flex justify-content-center mt-5" mat-dialog-content>
    <div [innerHTML]="data.message"></div>
</div>
<div *ngIf="!data.isOnlyConfirmation" class="d-flex gap justify-content-center mb-3">
    <button [mat-dialog-close]="true" cdkFocusInitial class="c-btn c-btn-oui w-auto">
        {{ 'UTILS.OUI' | translate }}
    </button>
    <button (click)="onNoClick()" class="c-btn c-btn--blue w-auto ml-3">
        {{ 'UTILS.NON' | translate }}
    </button>

</div>

<div *ngIf="data.isOnlyConfirmation" class="d-flex justify-content-center mb-3" mat-dialog-actions>
    <button [mat-dialog-close]="true" class="c-btn w-auto">OK</button>
</div>

