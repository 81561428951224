import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogComponent } from "@galec/modules/shared/components/dialog/dialog.component";
import {LangService} from "@galec/services/global/lang.service";

@Injectable({
    providedIn: 'root'
})
/**
 * Alert service to show popup message.
 */
export class AlertService {

    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly dialog: MatDialog,
        private readonly langService: LangService,
    ) {
    }

    /**
     * Show success message.
     * @param message: string   Corps message to display.
     * @param duration: number  Duration.
     */
    success(message, duration = 3000): void {
        this.snackBar.open(message, 'Fermer', {
            duration,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snack-success']
        });
    }

    /**
     * Show saved message.
     */
    save(): void {
        this.getTranslateSucces("SUCCES.ENREGISTRER");
    }

    /**
     * Show Delete message.
     */
    delete(): void {
        this.getTranslateSucces("SUCCES.SUPPRIMER");
    }

    /**
     * Show error message.
     * @param message: string   Corps message to display.
     */
    error(message): void {
        this.snackBar.open(message, 'Fermer', {
            duration: 50000000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snack-danger']
        });
    }

    apiError(): void {
        this.getTranslateError("ERREUR.API_ERREUR");
    }

    /**
     * Confirmation delete
     * @return Observable<any>      Observable after close dialog.
     */
    confirmationDelete(msg = 'Voulez-vous vraiment supprimer cet élément ?'): Observable<any> {
        return this.confirmationDialog(msg);
    }


    /**
     * Show confirmation dialog.
     * @param message string    Confirmation message.
     * @param title string    Popup title.
     * @param config MatDialogConfig    Confirmation option.
     * @return Observable<any>      Observable after close dialog.
     */
    confirmationDialog(message: string, title: string = '', isOnlyConfirmation?: boolean, config?: MatDialogConfig): Observable<any> {
        const dialogRef = this.dialog?.open(DialogComponent, {
            width: '350px',
            ...config,
            data: { message, title, isOnlyConfirmation },
        });
        return dialogRef?.afterClosed() ?? null;
    }

    private getTranslateSucces(path: string): void {
        this.langService.getTranslate(path).subscribe((message: string): void => {
            this.success(message);
        });
    }

    private getTranslateError(path: string): void {
        this.langService.getTranslate(path).subscribe((message: string): void => {
            this.error(message);
        });
    }
}
