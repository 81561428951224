<div class="c-error-page galec-403">
    <app-change-lang></app-change-lang>
    <div class="c-card">
        <img src="../assets/img/logo/galec-iam-logo-bleu.svg" class="w-100" alt="IAM Logo"/>
        <div class="c-error__title mt-5">{{ 'CATEGORIES.PAGE_403.TITRE' | translate }}</div>
        <div class="c-error__desc">{{ 'CATEGORIES.PAGE_403.ACCES_REFUSE' | translate }}</div>
        <div class="c-card__small-title mt-3">{{ 'CATEGORIES.PAGE_403.REFUS_SERVEUR' | translate }}</div>
        <a (click)="goBack()" class="c-btn mt-4">
            {{ 'UTILS.RETOUR_PAGE_ACCUEIL' | translate }}
            <i class="icon-fleche_droite"></i>
        </a>
        <a (click)="logout()" type="button" class="c-btn mt-4">
            {{ 'UTILS.DECONNECTER' | translate }}
        </a>
    </div>
</div>
