<section class="export-data-section">
    <h1>{{ 'CATEGORIES.ACCUEIL.EXPORT.TITRE' | translate }}</h1>
    <div class="groupe-btn">
        <button (click)="downloadDataStore()" [class]="getButtonClass()">
            {{ 'CATEGORIES.ACCUEIL.EXPORT.BOUTONS.POINTS_VENTES_ACTIFS' | translate }}
        </button>
        <div class="container-button">
            <button (click)="downloadDataActualite()" [class]="getButtonClass()">
                {{ 'CATEGORIES.ACCUEIL.EXPORT.BOUTONS.DONNEES_ACTUALITES.BOUTON' | translate }}
            </button>
            <p *ngIf="noActuality">{{ 'CATEGORIES.ACCUEIL.EXPORT.BOUTONS.DONNEES_ACTUALITES.ERREUR' | translate }}</p>
        </div>
        <div class="container-button" [formGroup]="formGroup">
            <select formControlName="year" class="select-pdv form-control w-50 mb-5">
                <option *ngFor="let year of years" [value]="year">
                    {{ year }}
                </option>
            </select>
            <button (click)="downloadDataOuvertureFermetureExceptionnelles()" [class]="getButtonClass()">
                {{ 'CATEGORIES.ACCUEIL.EXPORT.BOUTONS.OUV_FERM_EXCEPTIONNELLES.BOUTON' | translate }}
            </button>
            <p *ngIf="noExceptionalOpenClose">
                {{ 'CATEGORIES.ACCUEIL.EXPORT.BOUTONS.OUV_FERM_EXCEPTIONNELLES.ERREUR' | translate }}
            </p>
        </div>
    </div>
</section>